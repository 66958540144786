import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import { useTheme } from '@emotion/react';

const ActionMenu = React.forwardRef(({ isLoading, onDelete, groupId }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const activeProject = useActiveProject();
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        ref={ref}
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        disabled={isLoading}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {isLoading ? (
          <CircularProgress size={18} color={'primary'} />
        ) : (
          <MoreVert sx={{ color: theme.palette.svg.main }} />
        )}
      </IconButton>
      <Menu id="action-menu" anchorEl={anchorEl} open={open} onClose={handleClose} elevation={1}>
        <MenuItem
          as={NavLink}
          sx={{ color: 'inherit' }}
          to={`/projects/${activeProject?.id}/folders/tests-group/${groupId}`}>
          Open Group
        </MenuItem>
        <MenuItem sx={{ color: 'red '}} onClick={onDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
});

export default ActionMenu;
