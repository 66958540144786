import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Flex from '../../../components/base/Flex';
import CustomModal from '../../../components/base/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { Button } from 'rsuite';
import { useScandiumMutation, useScandiumQuery } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CustomScrollbar from './CustomScrollbar';
import { ColorContext } from '../../../AppTheme';
import CreateTestCaseTags from '../../projects/components/CreateTestCaseTags';
import useAwaitModal from '../../../hooks/useAwaitModal';

const texts = {
  passed: 'Passed',
  failed: 'Failed',
  draft: 'Draft',
  active: 'Active',
  retest: 'Retest',
  outdated: 'Outdated',
  blocked: 'Blocked',
  review: 'Review'
};

export const Badge = ({ status }) => {
  if (!status) return null;

  const colors = {
    passed: '#00CA08',
    failed: '#EF1B1B',
    draft: '#ADD8E6',
    active: '#00008B',
    retest: '#FFD700',
    outdated: '#FFA500',
    blocked: '#A52A2A',
    review: '#474747'
  };

  return (
    <Box
      sx={{
        py: 0.2,
        width: { xs: '50px', sm: '64px' },
        backgroundColor: colors[status],
        color: '#fff',
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: '0.7rem',
        borderTopLeftRadius: 5,
        borderBottomRightRadius: 5
      }}>
      {texts[status]}
    </Box>
  );
};

const StatusDropdown = ({
  value,
  onChange,
  UpdateTestOverview,
  testId,
  url,
  title,
  handleEditStatus,
  setStatus,
  shouldEdit
}) => {
  const options = Object.keys(texts).map((key) => ({
    value: key,
    label: texts[key]
  }));

  const handleStatusChange = async (e) => {
    onChange(e.target.value);
    setStatus((prev) => ({ shouldEdit: false, value: e.target.value }));

    await UpdateTestOverview({
      status: e.target.value,
      test_id: testId,
      name: title,
      starting_url: url
    });
  };

  return (
    <Box>
      <FormControl size={'small'} fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          placeholder="Click to select..."
          value={value}
          MenuProps={{
            elevation: 1
          }}
          onChange={handleStatusChange}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {shouldEdit && (
        <Flex justifyContent="flex-end" mt={1}>
          <Button size={'sm'} onClick={handleEditStatus}>
            Cancel
          </Button>
        </Flex>
      )}
    </Box>
  );
};

const EditableField = ({
  field,
  value,
  shouldEdit,
  onChange,
  onToggleEdit,
  onSave,
  displayText,
  onCancel
}) => {
  const { mode } = useContext(ColorContext);
  return (
    <Box mb={3}>
      <Typography variant={'body1'} fontWeight={500}>
        {field === 'expected_result'
          ? 'Expected Result'
          : field.charAt(0).toUpperCase() + field.slice(1)}
      </Typography>
      {shouldEdit ? (
        <TextField
          multiline
          size={'small'}
          minRows={3}
          maxRows={6}
          fullWidth
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <Typography
          variant={'body2'}
          onClick={onToggleEdit}
          sx={{
            position: 'relative',
            cursor: 'pointer',
            '&:hover': {
              outline: '2px solid #1958B8',
              outlineOffset: '3px'
            },
            ...(displayText
              ? {}
              : {
                  color: mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(250, 250, 250, 0.5)'
                })
          }}>
          {displayText || `No ${field === 'expected_result' ? 'Expected Result' : field}`}
        </Typography>
      )}
      {shouldEdit && (
        <Flex justifyContent="flex-end" mt={1}>
          <Button color="primary" size="sm" onClick={onSave}>
            Save
          </Button>
          <Button size={'sm'} onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      )}
    </Box>
  );
};

const TestOverviewModal = ({ open, onClose, testInfo, silentRefetchTest, url, title }) => {
  const { mode } = useContext(ColorContext);
  const activeProject = useActiveProject();
  const { testId } = useParams();
  const [status, setStatus] = useState({
    value: testInfo?.status || '',
    shouldEdit: false
  });
  const [overviewValues, setOverviewValues] = useState({
    links: {
      value: testInfo?.links || '',
      shouldEdit: false
    },
    precondition: {
      value: testInfo?.precondition || '',
      shouldEdit: false
    },
    description: {
      value: testInfo?.description || '',
      shouldEdit: false
    },
    scenarios: {
      value: testInfo?.scenarios || '',
      shouldEdit: false
    },
    expected_result: {
      value: testInfo?.expected_result || '',
      shouldEdit: false
    },
    comment: {
      value: testInfo?.comment || '',
      shouldEdit: false
    }
  });

  const [
    requestTestCaseTagsCreationUploadModal,
    {
      open: testCaseTagsCreationOpen,
      onClose: onCloseTestCaseTagsCreation,
      onComplete: completeTestCaseTagsCreation,
      ...otherProps
    }
  ] = useAwaitModal();

  useEffect(() => {
    if (testInfo) {
      setOverviewValues({
        links: {
          value: testInfo?.links || '',
          shouldEdit: false
        },
        precondition: {
          value: testInfo?.precondition || '',
          shouldEdit: false
        },
        description: {
          value: testInfo?.description || '',
          shouldEdit: false
        },
        scenarios: {
          value: testInfo?.scenarios || '',
          shouldEdit: false
        },
        expected_result: {
          value: testInfo?.expected_result || '',
          shouldEdit: false
        },
        comment: {
          value: testInfo?.comment || '',
          shouldEdit: false
        }
      });

      setStatus((prev) => ({ ...prev, value: testInfo?.status || '' }));
    }
  }, [testInfo]);

  const [editedValues, setEditedValues] = useState({});

  const { data: tags = [], refetch: refetchTags } = useScandiumQuery(`/tags`, {
    enabled: !!activeProject?.id && !!testCaseTagsCreationOpen,
    params: {
      project_id: activeProject?.id,
      type: 'test_case'
    },
    select: (data) => data.data
  });

  const updateOverviewValue = useCallback((field, newValue) => {
    setEditedValues((prevValues) => ({ ...prevValues, [field]: newValue }));
  }, []);

  const handleEdit = useCallback((field) => {
    setOverviewValues((prevValues) => ({
      ...prevValues,
      [field]: { ...prevValues[field], shouldEdit: !prevValues[field].shouldEdit }
    }));

    setEditedValues((prevValues) => ({ ...prevValues, [field]: overviewValues[field]?.value }));
  }, []);

  const handleCancel = useCallback((field) => {
    const updateEditedValues = { ...editedValues };

    const newState = Object.fromEntries(
      Object.entries(updateEditedValues).filter(([k]) => k !== field)
    );

    setEditedValues(newState);
    handleEdit(field);
  }, []);

  const handleEditStatus = () => {
    setStatus((prevValues) => ({ ...prevValues, shouldEdit: !status.shouldEdit }));
  };

  const { mutateAsync: UpdateTestOverview } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${testId}/overview`,
    {
      enabled: !!activeProject?.id,
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        silentRefetchTest();
      }
    }
  );

  const handleTestOverviewUpdate = async (field) => {
    const updateData = {
      [field]: field === 'links' ? [editedValues[field]] : editedValues[field],
      test_id: testId,
      starting_url: url,
      name: title,
      status: testInfo?.status || 'active'
    };

    // Optimistically update the UI
    setOverviewValues((prevValues) => ({
      ...prevValues,
      [field]: { value: editedValues[field], shouldEdit: false }
    }));

    await UpdateTestOverview(updateData);
  };

  return (
    <Box>
      <CustomModal open={open}>
        <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
          <IconButton
            onClick={onClose}
            size={'small'}
            sx={{
              position: 'absolute',
              top: '16px',
              right: '16px'
            }}>
            <CloseIcon
              sx={{
                fontSize: 25
              }}
            />
          </IconButton>

          <Flex sx={{ mb: 3 }}>
            <Typography as={'h4'} color="primary" fontWeight={500} sx={{ fontSize: '1.1rem' }}>
              Test case overview
            </Typography>

            {testInfo?.status && <Badge status={testInfo.status} />}
          </Flex>

          <CustomScrollbar
            sx={{
              overflowY: 'auto',
              maxHeight: '450px'
            }}>
            <Box mb={3}>
              <Typography variant={'body1'} fontWeight={500}>
                Status
              </Typography>
              {status.shouldEdit ? (
                <StatusDropdown
                  value={status.value}
                  onChange={setStatus}
                  UpdateTestOverview={UpdateTestOverview}
                  url={url}
                  title={title}
                  testId={testId}
                  setStatus={setStatus}
                  shouldEdit={status.shouldEdit}
                  handleEditStatus={handleEditStatus}
                />
              ) : (
                <Typography
                  variant={'body2'}
                  onClick={handleEditStatus}
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                    '&:hover': {
                      outline: '2px solid #1958B8',
                      outlineOffset: '3px'
                    },
                    ...(status.value
                      ? {}
                      : {
                          color:
                            mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(250, 250, 250, 0.5)'
                        })
                  }}>
                  {status.value || `No status`}
                </Typography>
              )}
            </Box>

            {Object.entries(overviewValues).map(([field, value]) => (
              <EditableField
                key={field}
                field={field}
                value={editedValues[field] || value.value}
                displayText={value.value}
                shouldEdit={value.shouldEdit}
                onChange={(newValue) => updateOverviewValue(field, newValue)}
                onToggleEdit={() => handleEdit(field)}
                onCancel={() => handleCancel(field)}
                onSave={() => handleTestOverviewUpdate(field)}
              />
            ))}

            <Box mb={4}>
              <Typography variant={'body1'} fontWeight={500}>
                Tags
              </Typography>
              <Typography
                variant={'body2'}
                onClick={() =>
                  requestTestCaseTagsCreationUploadModal({
                    testTags: testInfo?.tags?.map((tag) => tag.name.en) || [],
                    testId: testInfo?.id
                  })
                }
                sx={{
                  position: 'relative',
                  cursor: 'pointer',
                  '&:hover': {
                    outline: '2px solid #1958B8',
                    outlineOffset: '3px'
                  },
                  ...(testInfo?.tags?.length
                    ? {}
                    : {
                        color: mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(250, 250, 250, 0.5)'
                      })
                }}>
                {!testInfo?.tags?.length
                  ? 'No tags yet for this test'
                  : testInfo.tags.map((t) => t.name.en).join(', ')}
              </Typography>
            </Box>
          </CustomScrollbar>
        </Box>
      </CustomModal>
      <CreateTestCaseTags
        open={testCaseTagsCreationOpen}
        onClose={onCloseTestCaseTagsCreation}
        onComplete={completeTestCaseTagsCreation}
        tags={tags}
        refetchTags={silentRefetchTest}
        {...otherProps}
      />
    </Box>
  );
};

export default TestOverviewModal;
