/* global chrome */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BsRecordCircle } from 'react-icons/bs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomInput from '../../components/customInput';
import OutlinedButton from '../../components/base/OutlinedButton';
import Flex from '../../components/base/Flex';
import ContainedButton from '../../components/base/ContainedButton';
import { toast } from 'react-toastify';
import {
  BrowserSelect,
  EditableIndicator,
  FieldTitle,
  OSSelect,
  predefinedScreenSizes,
  ScreenSize
} from './components/TestProperties';
import { extensionEventHandler, getCurrentDate, getUserBrowser, getUserOS } from './utils';
import StopSvg from '../../components/svg/StopSvg';
import InstallExtensionCta from './components/InstallExtensionCta';
import ReplaySvg from '../../components/svg/ReplaySvg';
import { useFetchFolders } from '../../data-layer/project-management';
import { useActiveProject } from '../../store/projectState';
import { EditableTitle } from './components/EditableTitle';
import SaveIcon from '@mui/icons-material/Save';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import TestPageBody from './components/TestPageBody';
import { Badge, CircularProgress, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { useFetchTest, useSaveTestRun, useSaveTestSteps } from '../../data-layer/test-management';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import PageLoader from '../../components/PageLoader';
import useBlocker from '../../hooks/useBlocker';
import ProtocolMenu, { getProtocolFromString } from './components/ProtocolMenu';
import GetTestTitle from './components/GetTestTitleModal';
import { BulkStepActionsTrigger } from './components/BulkStepActions';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import { useDissociateGroup, useUpdateGroup } from '../../data-layer/groups';
import useMeta from '../../hooks/useMeta';
import { v4 } from 'uuid';
import TestSettingsSvg from '../../components/svg/TestSettingsSvg';
import AdditionalTestSettings from './components/AdditionalTestSettings';
import EmailMenu from './components/EmailMenu';
import RunTestMenu from './components/RunTestMenu';
import TestDetails from './components/TestDetails';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import DataSetMenu from './components/DataSetMenu';
import ErrorBoundary from '../../components/base/ErrorBoundary';
import ExportTestSvg from '../../components/svg/ExportTestSvg';
import useAwaitModal from '../../hooks/useAwaitModal';
import ExportTestModal from './components/ExportTestModal';
import { useScandiumRemoteMutation } from '../../data-layer/remote-execution';
import UserContext from '../../store/userState';
import { useGlobalVariables } from '../../store/globalVariables';
import { useTheme } from '@emotion/react';
import SaveSvg from '../../components/svg/SaveSvg';
import { ColorContext } from '../../AppTheme';
import BulkSettingsModal from './components/BulkSettingsModal';

export const getExtensionId = () => {
  return window.document.documentElement.getAttribute('scandium-extension-id');
};

export const getBaseUrl = (url) => {
  const _protocol = getProtocolFromString(url);

  // If the protocol is in the entered url string, extract the url without the protocol
  return !!_protocol && url.trim().indexOf(_protocol) === 0
    ? url.trim().slice(_protocol.length)
    : url;
};

const TestPage = ({ previewMode }) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { mode } = useContext(ColorContext);

  const { pathname } = useLocation();
  const { projectId, folderId, testId, batchId } = useParams();
  const activeProject = useActiveProject();
  const { globalVariables } = useGlobalVariables();
  const [operatingOS, setOperatingOS] = useState(getUserOS());

  const [browserType, setBrowserType] = useState(getUserBrowser());

  const [screenSize, setScreenSize] = useState(predefinedScreenSizes[0].value);
  const [customScreenSize, setCustomScreenSize] = useState(['', '']);
  const [screenSizeIndex, setScreenSizeIndex] = useState(0);
  const [activeTab, setActiveTab] = useState('scandium');

  const { meta } = useMeta();
  const { userData } = useContext(UserContext);

  const [url, setUrl] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isReplaying, setIsReplaying] = useState(false);
  const [events, setEvents] = useState([]);
  const [title, setTitle] = useState('Untitled test');
  const [description, setDescription] = useState('');
  const [savedTest, setSavedTest] = useState(null);
  const [isStepDetailsActive, setStepDetailsActive] = useState(false);
  const [playbackEvents, setPlaybackEvents] = useState(null);
  const [playbackData, setPlaybackData] = useState(null);
  const [isUnsavedRun, setUnsavedRun] = useState(false);
  const [isUnsaved, setUnsaved] = useState(false);
  const [playPaused, setPlayPaused] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [showAdditionalTestSettings, setShowAdditionalTestSettings] = useState(false);
  const [sessionId, setSessionId] = useState('');

  const [testGroups, setTestGroups] = useState([]);

  const [selectedStepIndexes, setSelectedStepIndexes] = useState([]);
  const selectedSteps = events.filter((_, index) => selectedStepIndexes.includes(index));
  const areAllEventsSelected = events.every((_, index) => selectedStepIndexes.includes(index));

  const readyOnly = activeProject?.read_only;

  // TODO: temporarily use a ref to store groups. This will be replaced with a more ideal solution when backend is implemented and we need to update state when groups change
  // const groupsRef = useRef([]);

  useDocumentTitle(testId ? `Edit Test${savedTest?.id ? `: ${savedTest.name}` : ''}` : 'New Test');

  // Check the URL to determine whether the test is on playground or production
  const currentUrl = window.location.href;
  const isPlayground = currentUrl.includes('playground');

  const isRecordingRef = useRef(isRecording);
  const isReplayingRef = useRef(isReplaying);
  // Prevents us from showing the results multiple times if we receive extension message multiple times
  const hasShownResultsRef = useRef(false);
  // Prevents us from saving the results multiple times if we receive extension message multiple times
  const isUnsavedRunRef = useRef(isUnsavedRun);
  const insertEventsAtRef = useRef(null);
  const extRef = useRef(getExtensionId());
  const startedReplayAtRef = useRef(null);
  const endReplayAtRef = useRef(null);

  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();

  const [
    requestExportTestModal,
    {
      open: openExportTestModal,
      onClose: onCloseExportTestModal,
      onComplete: completeExportTestModal
    }
  ] = useAwaitModal();

  const [
    requestBulkSettingsModal,
    {
      open: openBulkSettingsModal,
      onClose: onCloseBulkSettingsModal,
      onComplete: completeBulkSettingsModal
    }
  ] = useAwaitModal();

  // API actions that will be delayed until the save button is clicked. Requests that can be added here include dissociate test, save test groups.
  const pendingSaveRequestsRef = useRef({
    testRuns: [],
    testGroups: [],
    dissociateGroupIds: []
  });

  // State management utils, because we're tracking state AND refs
  const setIsUnsavedRunState = (value) => {
    isUnsavedRunRef.current = value;
    setUnsavedRun(value);
  };
  const setIsRecordingState = (value) => {
    isRecordingRef.current = value;
    setIsRecording(value);
  };
  const setIsReplayingState = (value) => {
    isReplayingRef.current = value;
    setIsReplaying(value);
  };

  const { mutateAsync: saveTest, isLoading: isSavingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${savedTest?.id || ''}`,
    {
      method: savedTest?.id ? 'PATCH' : 'POST',
      onSuccess: () => {
        trackMixPanel(TRACKING_IDS.TEST_SAVED, {
          type: 'web'
        });
      }
    }
  );
  const { mutateAsync: _saveTestSteps, isLoading: isSavingSteps } = useSaveTestSteps();
  const { mutateAsync: updateGroup } = useUpdateGroup();

  const saveTestSteps = async (data) => {
    try {
      await _saveTestSteps(data);
      setUnsaved(false);
    } catch (e) {
      setUnsaved(true);
      toast.error('Failed to save test steps. Please try again');
    }
  };

  const {
    data: testInfo,
    isLoading: isFetchingTest,
    error: fetchTestError,
    refetch: silentRefetchTest
  } = useFetchTest({
    projectId: activeProject?.id,
    testId,
    folderId,
    onSuccess: (data) => {
      setTitle(data.data.name);
      setOperatingOS(data.data.operating_system);
      setBrowserType(data.data.browser);
      setUrl(data.data.starting_url);
      setSavedTest(data.data);
      setDescription(data.data.description || '');
      setTestGroups(data.data.groups || []);
      if (!events.length && !!data.data.steps)
        setEvents(data.data.steps[data.data.steps.length - 1]?.meta || []);

      const matchingIndex = predefinedScreenSizes.findIndex(
        (size) => size.value[0] === data.data.width && size.value[1] === data.data.height
      );
      if (matchingIndex !== -1) {
        setScreenSize(predefinedScreenSizes[matchingIndex].value);
        setScreenSizeIndex(matchingIndex);
      } else {
        setScreenSizeIndex(-1);
        setCustomScreenSize([data.data.width, data.data.height]);
      }
    }
  });

  const {
    data: previousRun,
    isLoading: isFetchingRuns,
    refetch: refetchPreviousRuns
  } = useScandiumQuery(`/projects/${activeProject?.id}/test-runs/${batchId || sessionId}`, {
    enabled: !!activeProject?.id && !!testId && (!!batchId || (!sessionId && !!sessionId)),
    select: (data) => data?.data
  });

  const { data: consoleData } = useScandiumQuery(
    `/projects/${activeProject?.id}/test-runs/${batchId}/logs`,
    {
      enabled: !!activeProject?.id && !!testId && !!batchId,
      params: {
        type: 'console'
      },
      select: (data) => data?.data
    }
  );

  const { data: defaultProjectSettings } = useScandiumQuery(
    `/projects/${activeProject?.id}/settings`,
    {
      enabled: !!activeProject?.id,
      onSuccess: (data) => {
        if (!testId) {
          setUrl(data?.starting_url || '');
        }
      },
      select: (data) => data.data
    }
  );

  const {
    mutateAsync: convertTest,
    isLoading: isConvertingTest,
    data: convertedCodes
  } = useScandiumRemoteMutation(`/generate/${activeTab}`, {
    headers: {
      'x-api-token': userData?.user?.api_token || ''
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handleConvertTestcase = async () => {
    await convertTest({
      test_id: testId,
      project_id: projectId
    });
  };

  const { mutateAsync: dissociateGroup } = useDissociateGroup();

  const isPageLoading =
    isFetchingTest ||
    (isFetchingRuns && !refetchPreviousRuns) ||
    (!isFetchingTest && testId && !url && !fetchTestError);

  const { data: activeFolder } = useFetchFolders(activeProject?.id, {
    folderId,
    enabled: !!activeProject?.id && !!folderId,
    select: (data) => data.data
  });

  const { mutateAsync: _saveTestRun } = useSaveTestRun();
  const saveTestRun = async (testId, { startTime, endTime, results } = {}) => {
    const ranEvents = (results || playbackEvents || []).filter((event) => !!event.status);
    if (!ranEvents.length) return;

    const { steps, ...rests } = playbackData;

    try {
      setIsUnsavedRunState(false);
      await _saveTestRun({
        testId,
        projectId,
        folderId,
        data: {
          test_runs: ranEvents.map((event) => ({
            ...event
          })),
          ...rests
        }
      });
      trackMixPanel(TRACKING_IDS.TEST_RUN, {
        saved: true,
        mode: 'local',
        type: 'web'
      });
    } catch (e) {
      setIsUnsavedRunState(true);
      trackMixPanel(TRACKING_IDS.TEST_RUN, {
        saved: false,
        mode: 'local',
        type: 'web'
      });
      console.log(e);
      toast.error('Failed to save test run. Please try again by clicking the save button');
    }
  };

  const [openModal, setOpenModal] = useState(false);

  /**
   * Saves the entire test, as well as steps and any unsaved runs
   * @param _title custom title param to update the title state after closing the modal. Because setTitle is async and title may not have been updated when handleSaveTest is called again from TitleModal
   * @param _events
   * @param ghostMode don't show notifications on success
   * @returns {Promise<void>}
   */
  const handleSaveTest = async (_title = '', _events = null, ghostMode = false) => {
    // Use the _title param to update the title state
    if (_title) setTitle(_title);

    const baseUrl = getBaseUrl(url);

    const steps = _events || events || [];

    const testData = {
      name: _title || title,
      screen: 'desktop',
      width: screenSize[0],
      height: screenSize[1],
      operating_system: operatingOS,
      browser: browserType || getUserBrowser(),
      starting_url: `${protocol}${baseUrl}`,
      file_urls: fileUrls || [],
      description
    };

    if (folderId) testData['folder_id'] = folderId;

    try {
      if (!url) {
        toast.error('Please enter a starting url');
        return;
      }

      if (title === 'Untitled test' && !_title) {
        setOpenTitleModal(true);
        return;
      }

      const data = await saveTest(testData);
      toast.success(
        savedTest?.id ? 'Test configuration updated' : 'Test configuration saved successfully!'
      );

      setSavedTest(data.data);

      // if there are no events, the save is complete
      if (!steps.length) setUnsaved(false);

      await Promise.all([
        steps.length &&
          saveTestSteps({
            testId: data.data.id,
            folderId,
            projectId: activeProject.id,
            data: {
              test_steps: steps.map((event) => ({ ...event, meta: undefined, status: undefined }))
            }
          }),
        data?.data?.id &&
          isUnsavedRun &&
          startedReplayAtRef.current &&
          endReplayAtRef.current &&
          saveTestRun(data.data.id, {
            endTime: endReplayAtRef.current,
            startTime: startedReplayAtRef.current
          }),
        ...pendingSaveRequestsRef.current.testGroups.map((groupId) => {
          const data = testGroups.find((g) => g.id === groupId);
          return updateGroup({
            groupId: groupId,
            data: {
              title: data.title,
              steps: data.steps,
              meta: data.meta
            }
          });
        }),
        ...pendingSaveRequestsRef.current.dissociateGroupIds.map((groupId) => {
          return dissociateGroup({
            groupId: groupId,
            data: {
              test_id: testId
            }
          });
        })
        //   TODO: save batched runs here as well and remove it from above
      ]);
    } catch (error) {
      toast.error('An error occurred, please try again');
      console.log(error);
    }
  };

  const insertItemAt = (index, newArray, newItem) => {
    // Step 1: Check if the index is valid
    if (index < 0 || index > newArray.length) {
      console.error('Invalid index');
      return newArray;
    }

    newArray.splice(index, 0, newItem);

    return newArray;
  };

  /**
   *
   * @param event
   * @param onUpdate
   * @param isPlayback
   * @param insertAtIndex - The index at which to insert the next step. It should be last step index + 1
   */
  const handleUpdateEvent = (event, { isPlayback, onUpdate, insertAtIndex } = {}) => {
    const updater = isPlayback ? setPlaybackEvents : setEvents;

    updater((prev) => {
      const prevEvents = prev || [];
      const existingIndex = prevEvents.findIndex((e) => e.id === event.id);

      const { step, ..._event } = event;

      let newEvents;

      if (existingIndex !== -1) {
        // Update existing event
        newEvents = [
          ...prevEvents.slice(0, existingIndex),
          { ...prevEvents[existingIndex], ..._event },
          ...prevEvents.slice(existingIndex + 1)
        ];
      } else if (![null, undefined].includes(insertAtIndex)) {
        // Insert at specified index
        newEvents = [...insertItemAt(insertAtIndex, prevEvents, _event)];
        // Increment insertAtIndex for the next addition
        insertEventsAtRef.current = insertAtIndex + 1;
      } else {
        // Append to the end
        newEvents = [...prevEvents, _event];
      }

      if (existingIndex !== -1 && onUpdate) onUpdate(newEvents);

      setFileUrls((prev) => {
        const _files = newEvents.flatMap((obj) =>
          obj.files ? obj.files.map((fileObj) => fileObj.url).filter(Boolean) : []
        );
        return _files;
      });

      return newEvents;
    });

    // set unsaved changes
    isPlayback ? setIsUnsavedRunState(true) : setUnsaved(true);
  };

  const handleUpdateGroup = (group) => {
    const existingIndex = testGroups.findIndex((g) => g.id === group.id);

    if (existingIndex < 0) return;

    const newGroups = [
      ...testGroups.slice(0, existingIndex),
      { ...testGroups[existingIndex], ...group },
      ...testGroups.slice(existingIndex + 1)
    ];

    setTestGroups(newGroups);
    pendingSaveRequestsRef.current.testGroups = [
      ...new Set([...pendingSaveRequestsRef.current.testGroups, group.id])
    ];
    setUnsaved(true);
  };

  const handleBeforeUnload = (e) => {
    if (!(isUnsaved || isUnsavedRun)) return undefined;
    const warning =
      'It looks like you have some unsaved changes. Are you sure you want to leave this page?';
    e.returnValue = warning; //Gecko + IE
    return warning; //Gecko + Webkit, Safari, Chrome etc.
  };

  const handleExtensionEvent = useCallback((e) => {
    extensionEventHandler(e, extRef.current, {
      onStopRecording: (e) => {
        toast.success('Recording stopped 🛑');
        setIsRecordingState(false);
        trackMixPanel(TRACKING_IDS.RECORDING_STOPPED, {
          type: 'web'
        });
      },
      onPlayPaused: (e) => {
        toast.success('Replay paused 🛑');
        setIsReplayingState(false);
        setPlayPaused(true);
      },
      onEventRecorded: (data) => {
        handleUpdateEvent(data, {
          insertAtIndex: insertEventsAtRef.current
        });
        // if (insertEventsAtRef.current !== null) insertEventsAtRef.current += 1;
      },
      onEventPlayed: (data) => {
        if (!isReplayingRef.current) {
          toast.success('Replay started in a new window');
          setIsReplayingState(true);
        }

        if (!startedReplayAtRef.current) {
          startedReplayAtRef.current = Date.now();
        }

        handleUpdateEvent(data, {
          isPlayback: true,
          onUpdate: (updatedData) => {
            const existingIndex = updatedData.findIndex((e) => e.id === data.id);
            if (data.status === 'error') {
              toast.error(`Step ${existingIndex + 1} failed: ${updatedData[existingIndex].error}.`);
            }
          }
        });
      },
      onPlaybackEnded: async (data) => {
        setPlaybackData(data.result);
        const resultSteps = data.result?.steps || [];
        if (data.result?.steps)
          setPlaybackEvents((prevEvents) =>
            prevEvents.map((e) => {
              const event = resultSteps.find((d) => d.id === e.id);
              if (event) return { ...e, ...event };
            })
          );

        if (data.result?.started_at) startedReplayAtRef.current = data.result.started_at;
        if (data.result?.finished_at) endReplayAtRef.current = data.result.finished_at;
        //   update plabackSteps
        //   save run, but because of the async nature of setState, we will pass playbackEvents directly to the saveTestRun function
        setIsReplayingState(false);

        // For backward compatibility, we've commented this out and kept the original useEffect that shows the toast status
        // TODO: uncomment this and remove the useEffect that show the toast
        // if (data.result?.status === 'error')
        //   toast.error(
        //     `Test failed and exited on step: ${resultSteps[resultSteps.length - 1].title}`
        //   );
        // else {
        //   const allPassed = resultSteps.every((step) => step.status === 'success');
        //   const failedCount = resultSteps.filter((step) => step.status === 'error').length;
        //   const skippedCount = resultSteps.filter((step) => step.status === 'skipped').length;
        //   const passCount = resultSteps.filter((step) => step.status === 'success').length;
        //
        //   if (allPassed) toast.success('All tests passed 🎉');
        //   else
        //     toast.info(
        //       `Test complete! ${passCount} steps passed, ${skippedCount} skipped, ${failedCount} failed`
        //     );
        // }

        // // If the test is already saved, allow extension to save the run result
        // if (!data.run_saved && testId && isUnsavedRunRef.current) {
        //   await saveTestRun(testId, {
        //     startTime: startedReplayAtRef.current,
        //     endTime: endReplayAtRef.current,
        //     results: resultSteps
        //   });
        //   endReplayAtRef.current = null;
        //   startedReplayAtRef.current = null;
        // }

        // if extension already saved run results, reset run states
        if (!!data.run_saved && !!testId) {
          setIsUnsavedRunState(false);
          setUnsavedRun(false);
          // setUnsaved(false);
          endReplayAtRef.current = null;
          startedReplayAtRef.current = null;
        }

        if (!!data.result?.id) {
          setSessionId(data.result.id);
        }
      }
    });
  }, []);

  const startRecording = (insertAtIndex) => {
    // TODO: move these all to an external verification hook
    if (!window.chrome) {
      toast.error(
        'The Scandium extension only works on Chrome for now. Other browsers are coming soon'
      );
      return;
    }

    if (!url) {
      toast.error('Please enter a starting url');
      return;
    }

    if (!screenSize || !screenSize[0] || !screenSize[1]) {
      toast.error('Please enter a valid screen size');
      return;
    }

    // fetching it here again in case you decide for some reason to uninstall the extension after page initializes
    extRef.current = getExtensionId();
    if (!extRef.current) {
      setOpenModal(true);
      toast.error(
        'The Scandium extension is yet to be installed on your browser. Please install the extension and try again'
      );
    }

    const baseUrl = getBaseUrl(url);

    // insert new events at the end
    insertEventsAtRef.current = insertAtIndex || null;
    chrome.runtime.sendMessage(
      extRef.current,
      {
        cmd: 'openNewWindowForRecording',
        url:
          (insertAtIndex !== null && events[insertAtIndex - 1]?.page?.url) ||
          `${protocol}${baseUrl}`,
        width: screenSize[0],
        steps: events,
        groups: testGroups,
        start_index: insertAtIndex,
        height: screenSize[1],
        mailbox_url: isPlayground
          ? `https://playground.getscandium.com/projects/${projectId}/mailbox`
          : `https://app.getscandium.com/projects/${projectId}/mailbox`,
        meta: meta,
        project_settings: defaultProjectSettings || {}
      },
      (res) => {
        if (!isRecordingRef.current) {
          toast.success('Recording started in a new window 🚀');
          setIsRecordingState(true);
        }
      }
    );

    trackMixPanel(TRACKING_IDS.RECORDING_STARTED, {
      type: 'web'
    });

    toast.success('Connecting to the extension');
  };

  const stopRecording = (e) => {
    chrome.runtime.sendMessage(
      extRef.current,
      {
        cmd: 'stop-recording',
        meta: meta
      },
      (res) => {
        toast.success('Recording stopped 🛑');
        setIsRecordingState(false);
      }
    );
  };

  const startPlayback = (index, stepIndexInGroup) => {
    trackMixPanel(TRACKING_IDS.START_REPLAY, {
      type: 'web'
    });

    // In case you somehow got here without having the extension. Maybe because the steps were fetched from the api
    extRef.current = getExtensionId();
    if (!extRef.current) {
      setOpenModal(true);
      toast.error(
        'The Scandium extension is yet to be installed on your browser. Please install the extension and try again'
      );
    }

    // reset playback times
    startedReplayAtRef.current = null;
    endReplayAtRef.current = null;
    hasShownResultsRef.current = false;

    setPlaybackEvents([]);

    chrome.runtime.sendMessage(
      extRef.current,
      {
        cmd: 'openNewWindowForPlayback',
        steps: events,
        source: 'local',
        test_data: {
          id: testId || '',
          name: title,
          is_dirty: isUnsaved,
          height: screenSize[1],
          width: screenSize[0],
          starting_url: `${protocol}${getBaseUrl(url)}`,
          groups: testGroups,
          steps: events,
          variables: savedTest?.variables || [],
          data_source: savedTest?.data_source || [],
          pre_step_delay: savedTest?.pre_step_delay || null,
          step_timeout: savedTest?.step_timeout || null
        },
        global_variables: globalVariables || [],
        start_index: index,
        step_index_in_group: stepIndexInGroup,
        meta: meta
      },
      (res) => {}
    );
  };

  const stopPlayback = () => {
    trackMixPanel(TRACKING_IDS.STOP_REPLAY);

    chrome.runtime.sendMessage(extRef.current, { cmd: 'stop-playback', meta: meta }, (res) => {
      // We put this in the callback because some events may come in after the stop command is sent, which will inadvertently trigger the whole eventPlayed process again
      isReplayingRef.current = false;
    });
    // We use this to immediately update the UI
    setIsReplaying(false);
    toast.success('Playback terminated 🛑');
  };

  const resumePlayback = () => {
    chrome.runtime.sendMessage(extRef.current, { cmd: 'resume', meta: meta }, (res) => {});
    // We use this to immediately update the UI
    setIsReplaying(true);
    setPlayPaused(false);
    toast.success('Playback resume 🚀');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUnsavedRun, isUnsaved]);

  useEffect(() => {
    window.addEventListener('message', handleExtensionEvent);
    const extVersion = getExtensionId();
    if (extVersion === null) setOpenModal(true);

    return () => {
      window.removeEventListener('message', handleExtensionEvent);
    };
  }, []);

  useBlocker(
    'It looks like you have some unsaved changes. Are you sure you want to leave this page',
    !!(isUnsaved || isUnsavedRun)
  );

  // Navigate away when unsaved or unsavedRun changes to false and there is a
  useEffect(() => {
    if (!isUnsaved && !isUnsavedRun) {
      // If there is no testId in the url
      if (!testId && !!savedTest) {
        navigate(pathname.replace('new', `${savedTest.id}/edit`), { replace: true });
      }
    }
  }, [isUnsaved, testId, isUnsavedRun, savedTest]);

  // TODO: remove this when we don't have to worry about people using old versions of the extension and we want to rely on playback-ended to know the status
  useEffect(() => {
    if (!playbackEvents || !playbackEvents.length) return;
    const lastItem = playbackEvents[playbackEvents.length - 1];
    const existingIndex = events.findIndex((e) => e.id === lastItem.id);
    if (existingIndex === -1) return;
    if (
      existingIndex === events.length - 1 ||
      (lastItem.status === 'error' && events[existingIndex].onError === 'fail')
    ) {
      setIsReplayingState(false);
      endReplayAtRef.current = Date.now();

      if (hasShownResultsRef.current) return;
      if (lastItem.status === 'error')
        toast.error(`Test failed and exited on step ${existingIndex + 1}`);
      else {
        const allPassed = playbackEvents.every((step) => step.status === 'success');
        const failedCount = playbackEvents.filter((step) => step.status === 'error').length;
        const skippedCount = playbackEvents.filter((step) => step.status === 'skipped').length;
        const passCount = playbackEvents.filter((step) => step.status === 'success').length;

        if (allPassed) toast.success('All tests passed 🎉');
        else
          toast.info(
            `Test complete! ${passCount} steps passed, ${skippedCount} skipped, ${failedCount} failed`
          );
      }
      hasShownResultsRef.current = true;
    }
  }, [playbackEvents]);

  const [openTitleModal, setOpenTitleModal] = useState(false);
  const [protocol, setProtocol] = useState('https://');

  const deselectSteps = (steps) => {
    if (selectedStepIndexes.length) {
      // This is unnecessarily complicated, but it already accounts for if we want to delete/deselect x steps out of y selected steps. I have no idea why we'd ever need this, but I'll keep it anyway
      const newSelectedSteps = selectedStepIndexes
        .map((i) => {
          if (steps.includes(i)) return null;
          //   count number of steps i is greater than in steps
          const count = steps.filter((j) => j < i).length;
          return i - count;
        })
        .filter((i) => i !== null);
      setSelectedStepIndexes(newSelectedSteps);
    }
  };

  // select all steps
  const selectAllSteps = () => {
    const newArrayOfIndexes = events.reduce((accumulator, event, index) => {
      if (index !== 0 && event.type !== 'group') {
        accumulator.push(index);
      }
      return accumulator;
    }, []);
    const allSelected = newArrayOfIndexes.every((i) => selectedStepIndexes.includes(i));

    if (allSelected) {
      // Currently all are selected -> Deselect all
      setSelectedStepIndexes([]);
    } else {
      // Some or none are selected -> Select all
      setSelectedStepIndexes(newArrayOfIndexes);
    }
  };

  const deleteSteps = async (steps) => {
    // if steps are selected, deselect them
    // Because indexes will shift after deletion, we simply remove the indexes of the steps to be deleted
    deselectSteps(steps);
    const newEvents = events.filter((e, index) => !steps.includes(index));
    setEvents(newEvents);
    setUnsaved(true);
    // silently dissociate any deleted groups from the test. We don't care here, but this is used to update the tests using this group on the groups page
    // Note that this is idealistic, because on the current design we can't even select multiple groups to delete, but it's nice to have this anyway. For now, we only delete one group at a time and this still works for that
    const deletedGroupIndexes = steps.filter((i) => events[i].type === 'group');
    if (deletedGroupIndexes.length) {
      deletedGroupIndexes.forEach((groupIndex) => {
        // Not awaiting because we want them to be in parallel
        dissociateGroup({
          groupId: events[groupIndex].id,
          data: {
            test_id: testId
          }
        });
      });

      // Save the test itself
      try {
        await handleSaveTest(undefined, newEvents, true);
        await silentRefetchTest();
      } catch (e) {
        setUnsaved(true);
      }
    }
  };

  // Duplicate steps
  const duplicateSelectedSteps = (steps) => {
    setEvents((prevEvents) => {
      const newEvents = [...prevEvents];

      // Iterate over the selected step indexes in reverse order
      // to ensure that duplications are added right after the original steps
      for (let i = selectedStepIndexes.length - 1; i >= 0; i--) {
        const selectedIndex = selectedStepIndexes[i];

        if (selectedIndex >= 0 && selectedIndex < newEvents.length) {
          const selectedStep = newEvents[selectedIndex];

          const duplicatedStep = {
            ...selectedStep,
            id: v4(),
            title: `Copy of ${selectedStep.title}`
          };

          newEvents.splice(selectedIndex + 1, 0, duplicatedStep);
        }
      }

      return newEvents;
    });

    setUnsaved(true);
    deselectSteps(steps);
  };

  // update step baseline image
  const updateEventScreenshot = (eventId, newImageData) => {
    const _events = [...events];
    const updatedEvents = _events.map((event) =>
      event.id === eventId
        ? { ...event, screenshot: { ...event.screenshot, imageData: newImageData } }
        : event
    );

    // check to see if events were modified before updating
    if (JSON.stringify(updatedEvents) !== JSON.stringify(events)) {
      setEvents(updatedEvents);
      if (testId) {
        saveTestSteps({
          testId,
          folderId,
          projectId: activeProject.id,
          data: {
            test_steps: updatedEvents
          }
        });
      }
    }
  };

  // handle add custom javascript step
  const addCustomJavascriptStep = (index, title = 'Execute javascript step', expression) => {
    if (!url) {
      toast.error('You must provide a starting URL before you can create this new step');
      return;
    }

    const js_step = {
      id: v4(),
      title,
      expression,
      preStepDelay: 1000,
      stepTimeout: 30000,
      onError: 'fail',
      type: 'js-step',
      page: {
        url
      }
    };

    setEvents((prevEvents) => [
      ...prevEvents.slice(0, index),
      { ...js_step },
      ...prevEvents.slice(index)
    ]);
    setUnsaved(true);
    toast.success('Custom Javascript step added successfully');
  };

  const handleUpdateEventScreenshot = async (eventId, newImageData) => {
    if (await requestConfirm()) return updateEventScreenshot(eventId, newImageData);
  };

  // Bulk steps update
  const updateBulkEvents = (updatedEvent, updateElement = false) => {
    setSelectedStepIndexes((prevSelectedIndexes) => {
      const updatedEvents = events.map((event, index) => {
        if (prevSelectedIndexes.includes(index)) {
          if (updateElement && !event.element) {
            return event;
          } else {
            return {
              ...event,
              ...(updateElement ? { element: { ...event.element, ...updatedEvent } } : updatedEvent)
            };
          }
        }
        return event;
      });
      setEvents(updatedEvents);
      return prevSelectedIndexes;
    });

    setUnsaved(true);
  };

  /**
   * ======================
   * Drag and drop handlers
   * ======================
   */

  const reorderSteps = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === dragIndex) return;

      // remove previous from the previous hover state
      const newSteps = events.map(({ preview, ...event }) => event);
      const draggedCard = newSteps[dragIndex];

      newSteps.splice(dragIndex, 1); // Remove the dragged card
      newSteps.splice(hoverIndex, 0, {
        ...draggedCard,
        preview: true,
        fromIndex: [null, undefined].includes(draggedCard.fromIndex)
          ? dragIndex
          : draggedCard.fromIndex
      }); // Add the empty card at the hovered position
      setEvents(newSteps);
      setUnsaved(true);
    },
    [events, setEvents, setUnsaved]
  );

  const handleDragEnd = useCallback(() => {
    // cleanup step. Remove unwanted temporary properties
    const newSteps = events.map(({ preview, fromIndex, ...event }) => event);
    setEvents(newSteps);
    setUnsaved(true);
  }, [events, setEvents, setUnsaved]);

  const insertStepAt = (step, index) => {
    const newEvents = [...events];
    newEvents.splice(index, 0, step);
    setEvents(newEvents);
    setUnsaved(true);
  };

  /**
   * ======================
   * Group handlers
   * ======================
   */

  const afterGroupCreated = async (group, stepIndexes) => {
    // silently refetch testInfo
    const _stepIndexes = stepIndexes.sort();

    const groupStep = {
      id: group.id,
      type: 'group',
      title: group.title,
      description: group.description,
      stepCount: group.steps.length,
      meta: {}
    };

    // remove steps from events, and insert group at the position of the first step
    const newEvents = events.filter((e, index) => !_stepIndexes.includes(index));
    newEvents.splice(_stepIndexes[0], 0, groupStep);
    setEvents(newEvents);
    setTestGroups((prev) => [...prev, group]);
    deselectSteps(_stepIndexes);
    setUnsaved(true);
  };

  const afterGroupDuplicated = async (groupData, oldGroupId) => {
    // dissociate the test from the group. We don't care here, but this is used to update the tests using this group on the groups page
    pendingSaveRequestsRef.current.dissociateGroupIds = [
      ...new Set([...pendingSaveRequestsRef.current.dissociateGroupIds, oldGroupId])
    ];
    const newEvents = [...events];
    const groupIndex = newEvents.findIndex((e) => e.id === oldGroupId);
    if (groupIndex !== -1) {
      newEvents.splice(groupIndex, 1, groupData);
    }
    setEvents(newEvents);
    // Add new group to the list of groups associated with the test
    setTestGroups((prev) => [...prev, groupData]);
    setUnsaved(true);
  };

  const onGroupAssociated = async (group, stepIndexes, didAddSteps = true, insertAfter = false) => {
    const _stepIndexes = stepIndexes.sort();

    const groupStep = {
      id: group.id,
      type: 'group',
      title: group.title,
      description: group.description,
      stepCount: group.steps?.length,
      meta: {}
    };

    // remove steps from events if didAddSteps is true
    const newEvents = didAddSteps
      ? events.filter((e, index) => !_stepIndexes.includes(index))
      : [...events];
    // find index of the group in the events array. If it's -1, then we need to insert the group at the position of the first step
    // otherwise, we move the position of the group to min(stepIndexes[0], groupIndex)
    const groupIndex = newEvents.findIndex((e) => e.id === group.id);
    if (groupIndex === -1) {
      const insertionPoint = insertAfter ? _stepIndexes[0] + 1 : _stepIndexes[0];
      newEvents.splice(insertionPoint, 0, groupStep);
    } else {
      if (groupIndex > _stepIndexes[0]) {
        const targetIndex = insertAfter ? _stepIndexes[0] + 1 : _stepIndexes[0];

        // move group from groupIndex to _stepIndexes[0]
        newEvents.splice(groupIndex, 1);
        newEvents.splice(targetIndex, 0, groupStep);
      }
    }
    setEvents(newEvents);
    setTestGroups((prev) => [...prev, group]);
    deselectSteps(stepIndexes);

    setUnsaved(true);

    await handleSaveTest(undefined, newEvents, undefined, true)
  };

  const _refetchTest = async () => {
    // save test first to avoid discarding dirty state. If we need to add one more param to this function, we better make them named, cause this undefined is getting ridiculous
    if (isUnsaved) await handleSaveTest(undefined, undefined, undefined, true);
    await silentRefetchTest();
  };

  const getDisableBulkActionReason = () => {
    if (!testId)
      return {
        create_group: 'Please save the test first',
        add_to_group: 'Please save the test first',
        insert_group_before: 'Please save the test first',
        insert_group_after: 'Please save the test first'
      };
    if (selectedStepIndexes.includes(0))
      return {
        create_group: 'Cannot add the first step to a group',
        add_to_group: 'Cannot add the first step to a group',
        delete: 'Cannot delete the first step'
      };
    if (selectedStepIndexes?.length > 1)
      return {
        insert_group_before: 'Please make only one selection',
        insert_group_after: 'Please make only one selection'
      };
    return {};
  };

  return (
    <Box
      sx={{
        marginTop: { xs: '3.5rem', sm: '9rem' },
        ml: { xs: '1.5rem', sm: '2rem', md: '4rem' },
        mr: { xs: '1.5rem', sm: '2rem', md: '7rem' },
        color: 'inherit',
        position: 'relative'
      }}>
      {/* Top/Metadata section */}
      {!isStepDetailsActive && !showAdditionalTestSettings && (
        <Box
          sx={{
            position: 'sticky',
            top: 113,
            zIndex: 1200,
            bgcolor: theme.palette.background.testPagePanel
          }}>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Flex columnGap={0} alignItems={'flex-end'} flexWrap={'nowrap'}>
              <Typography
                as={Link}
                to={`/projects/${projectId}/folders/${folderId || ''}`}
                sx={{
                  textDecoration: 'none',
                  textAlign: 'left',
                  fontSize: '1.1rem',
                  color: theme.palette.text.black_white,
                  opacity: mode === 'dark' ? 0.6 : 0.4
                }}>
                {activeFolder?.name || `Test case`} /
              </Typography>
              <EditableTitle
                value={title}
                disabled={previewMode}
                InputProps={{
                  endAdornment: previewMode ? undefined : (
                    <InputAdornment position="end">
                      <EditableIndicator showHelper />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ px: 0, width: title ? title.length * 8 : 200, minWidth: 200 }}
              />
            </Flex>

            <Box
              sx={{
                py: '0.2rem',
                px: '1rem',
                gap: '1.5rem',
                color: 'inherit',
                bgcolor: 'rgba(36, 195, 217, 0.16)'
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <ScreenSize
                  onChange={!previewMode && setScreenSize}
                  customScreenSize={customScreenSize}
                  setCustomScreenSize={setCustomScreenSize}
                  screenSizeIndex={screenSizeIndex}
                  setScreenSizeIndex={setScreenSizeIndex}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <OSSelect value={operatingOS} onChange={!previewMode && setOperatingOS} />
              </Box>
              <Box display="flex" flexDirection="column">
                <BrowserSelect onChange={!previewMode && setBrowserType} value={browserType} />
              </Box>
              <Flex>
                <Box
                  sx={{ cursor: previewMode || !!isPageLoading ? 'default' : 'pointer' }}
                  onClick={() =>
                    previewMode || !!isPageLoading
                      ? undefined
                      : setShowAdditionalTestSettings((prev) => !prev)
                  }>
                  <Tooltip title={'Settings'}>
                    <IconButton size={'small'}>
                      <TestSettingsSvg
                        fill={theme.palette.svg.primary}
                        previewMode={previewMode}
                        width={20}
                        height={20}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <EmailMenu theme={theme} />
                <DataSetMenu previousRun={previousRun} />
                <TestDetails
                  previousRun={previousRun}
                  os={operatingOS}
                  browser={browserType}
                  savedTest={savedTest}
                  testInfo={testInfo}
                  silentRefetchTest={silentRefetchTest}
                  url={url}
                  title={title}
                />
              </Flex>
            </Box>
          </Flex>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { md: 'column', lg: 'row' },
              mt: 1.5,
              pb: 2,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Flex columnGap={0} alignItems={'stretch'} width={'40%'}>
              <ProtocolMenu
                protocol={protocol}
                setProtocol={(protocol) => {
                  if (url) {
                    const _protocol = getProtocolFromString(url);
                    const baseUrl = getBaseUrl(url);
                    // If there was a protocol, replace it with the selected one
                    setUrl(`${_protocol ? protocol : ''}${baseUrl}`);
                  }
                  setProtocol(protocol);
                }}
              />
              <CustomInput
                required
                value={url}
                onChange={(event) => {
                  const _protocol = getProtocolFromString(event.target.value);
                  if (_protocol) setProtocol(_protocol);
                  setUrl(event.target.value);
                }}
                disabled={previewMode || !!readyOnly}
                size={'small'}
                label="Enter a starting URL to record e.g. https://getscandium.com"
                formControlStyle={{
                  width: '100%'
                }}
                sx={{
                  paddingLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  overflow: 'hidden',
                  fontSize: 12
                }}
                inputProps={{
                  sx: { paddingLeft: 1, py: 1, fontSize: 12 }
                }}
                endAdornment={
                  (isPageLoading && (
                    <InputAdornment position="end">
                      <CircularProgress size={16} color={'secondary'} />
                    </InputAdornment>
                  )) ||
                  undefined
                }
                error={false}
                helperText="ojfalse"
              />
            </Flex>
            <Flex>
              {selectedStepIndexes.length > 0 && (
                <BulkStepActionsTrigger
                  testGroups={testGroups}
                  areAllEventsSelected={areAllEventsSelected}
                  selectedSteps={selectedSteps}
                  disableReason={getDisableBulkActionReason()}
                  selectAllSteps={selectAllSteps}
                  onGroupCreated={(group) => {
                    return afterGroupCreated(group, selectedStepIndexes);
                  }}
                  onGroupAssociated={(group, didAddSteps, insertAfter) => {
                    return onGroupAssociated(group, selectedStepIndexes, didAddSteps, insertAfter);
                  }}
                  onDeleteSteps={() => {
                    deleteSteps(selectedStepIndexes);
                  }}
                  onDuplicateSteps={() => {
                    duplicateSelectedSteps(selectedStepIndexes);
                  }}
                  onRequestBulkSettings={() => requestBulkSettingsModal()}
                />
              )}
              <Tooltip
                title={
                  'Convert your Scandium test cases into Selenium, Playwright, or Puppeteer code formats'
                }>
                <OutlinedButton
                  disabled={previewMode || !!readyOnly || !testId || !testId}
                  sx={{ minHeight: '28px' }}
                  startIcon={<ExportTestSvg fill={theme.palette.svg.primary} />}
                  onClick={() => {
                    requestExportTestModal();
                    if (activeTab !== 'scandium') {
                      handleConvertTestcase();
                    }
                  }}>
                  Export
                </OutlinedButton>
              </Tooltip>
              {playPaused && (
                <Tooltip title={'Resume play'}>
                  <IconButton onClick={resumePlayback}>
                    <NotStartedIcon color={'primary'} />
                  </IconButton>
                </Tooltip>
              )}
              <OutlinedButton
                disabled={previewMode || !!readyOnly}
                sx={{ minHeight: '28px' }}
                startIcon={
                  isRecording ? (
                    <StopSvg />
                  ) : (
                    <BsRecordCircle color={theme.palette.svg.main} size="1rem" />
                  )
                }
                onClick={isRecording ? stopRecording : () => startRecording()}>
                {isRecording ? 'Stop' : 'Record'}
              </OutlinedButton>
              <OutlinedButton
                disabled={!events.length || !!readyOnly}
                loadingProps={{ size: 16 }}
                sx={{
                  borderTopLeftRadius: '0.4rem',
                  borderBottomLeftRadius: '0.4rem',
                  borderTopRightRadius: '0rem',
                  borderBottomRightRadius: '0rem',
                  borderRight: 'none',
                  minHeight: '28px'
                }}
                startIcon={
                  isReplaying ? (
                    <CircularProgress size={16} />
                  ) : (
                    <ReplaySvg disabled={!events.length} fill={theme.palette.svg.primary} />
                  )
                }
                onClick={isReplaying ? stopPlayback : () => startPlayback()}>
                {isReplaying ? 'Stop Playback' : 'Run'}
              </OutlinedButton>
              <RunTestMenu
                events={events}
                startPlayback={startPlayback}
                url={url}
                protocol={protocol}
                setProtocol={setProtocol}
                browser={browserType}
                setUrl={setUrl}
              />
              {/*Can't edit in previewMode*/}
              {!previewMode && (
                <Badge
                  color="secondary"
                  variant="standard"
                  badgeContent={!(isUnsaved || isUnsavedRun) ? undefined : ' '}>
                  <ContainedButton
                    isLoading={isSavingTest || isSavingSteps}
                    disabled={!!readyOnly}
                    loadingProps={{ size: 14 }}
                    sx={{ minHeight: '28px' }}
                    startIcon={<SaveSvg fill={theme.palette.svg.main} />}
                    onClick={() => handleSaveTest()}>
                    Save
                  </ContainedButton>
                </Badge>
              )}
              {!!previewMode && !!testId && (
                <ContainedButton
                  as={NavLink}
                  disabled={!!readyOnly}
                  sx={{ textDecoration: 'none', display: 'inline-flex', minHeight: '28px' }}
                  to={`/projects/${activeProject?.id}/folders/${
                    folderId ? `${folderId}/` : ''
                  }tests/${testId}/edit`}
                  isLoading={isSavingTest || isSavingSteps}
                  loadingProps={{ size: 16 }}>
                  Edit Test
                </ContainedButton>
              )}
            </Flex>
          </Box>
        </Box>
      )}
      <InstallExtensionCta openModal={openModal} setOpenModal={setOpenModal} />

      {isPageLoading && !showAdditionalTestSettings && <PageLoader height={'100px'} />}

      {/*  Body */}
      {!isPageLoading && !showAdditionalTestSettings && (
        <ErrorBoundary>
          <TestPageBody
            events={events}
            testGroups={testGroups}
            error={fetchTestError}
            refetchTest={_refetchTest}
            testTitle={title}
            failureTag={previousRun?.failure_tag}
            refetchPreviousRuns={refetchPreviousRuns}
            sessionId={sessionId}
            runResults={playbackEvents || previousRun?.items}
            onUpdateEvent={!previewMode && handleUpdateEvent}
            onUpdateGroup={!previewMode && handleUpdateGroup}
            handleRecord={startRecording}
            startPlayback={startPlayback}
            afterGroupDuplicated={afterGroupDuplicated}
            handleDelete={async (index, afterDelete) => {
              deleteSteps([index]);
              afterDelete?.();
            }}
            onReorderSteps={reorderSteps}
            onDragEnd={handleDragEnd}
            onToggleStepDetails={(show) => setStepDetailsActive(show)}
            selectedSteps={selectedStepIndexes}
            setSelectedSteps={setSelectedStepIndexes}
            insertEventAt={insertStepAt}
            testCaseVariables={savedTest?.variables || []}
            dataSource={savedTest?.data_source}
            handleUpdateEventScreenshot={handleUpdateEventScreenshot}
            consoleData={consoleData}
            globalVariables={globalVariables}
            // The props below are for TestRunSettinsBar to enable insertion of groups before or after a step
            onGroupAssociated={onGroupAssociated}
            addCustomJavascriptStep={addCustomJavascriptStep}
          />
        </ErrorBoundary>
      )}

      {/* Additional Test settings */}
      {!!showAdditionalTestSettings && (
        <AdditionalTestSettings
          title={title}
          browserType={browserType}
          operatingOS={operatingOS}
          savedTest={savedTest}
          silentRefetchTest={silentRefetchTest}
          onClose={() => setShowAdditionalTestSettings((prev) => !prev)}
          handleSaveTest={handleSaveTest}
          description={description}
          setDescription={setDescription}
          isSavingTest={isSavingTest}
          setTitle={setTitle}
          customScreenSize={customScreenSize}
          setCustomScreenSize={setCustomScreenSize}
          screenSize={screenSize}
          setScreenSize={setScreenSize}
          screenSizeIndex={screenSizeIndex}
          setScreenSizeIndex={setScreenSizeIndex}
        />
      )}

      <GetTestTitle
        open={openTitleModal}
        handleClose={() => setOpenTitleModal(false)}
        handleSave={async (title) => {
          return await handleSaveTest(title);
        }}
      />

      <ExportTestModal
        open={openExportTestModal}
        onClose={onCloseExportTestModal}
        onCompleteCreation={completeExportTestModal}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isLoading={isConvertingTest}
        handleConvertTestcase={handleConvertTestcase}
        convertedCodes={convertedCodes}
        events={events}
      />

      <BulkSettingsModal
        open={openBulkSettingsModal}
        onClose={onCloseBulkSettingsModal}
        onComplete={completeBulkSettingsModal}
        handleBulkEventsUpdate={updateBulkEvents}
      />

      <ConfirmationDialog
        title={'Set Result as Baseline?'}
        description={
          'Are you sure you want to set the result image as the baseline image for this step? This will replace the current baseline image.'
        }
        confirmLabel={'confirm'}
      />
    </Box>
  );
};

export default TestPage;
