import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { useCookies } from 'react-cookie';
import {
  Typography,
  Box,
  InputAdornment,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
  TextField,
  Button,
  Stack,
  styled,
  Fade,
  Badge,
  useTheme
} from '@mui/material';
import axios from 'axios';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { FloatingVariableWindowContext } from '../../store/floatingVariableWindowState';
import { GlobalModal } from './components/GloblaModal';
import { DescriptionModal } from './components/DescriptionModal';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import OutlinedButton from '../../components/base/OutlinedButton';
import Flex from '../../components/base/Flex';
import ContainedButton from '../../components/base/ContainedButton';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { EditableTitle } from '../TestPage/components/EditableTitle';
import { EditableIndicator } from '../TestPage/components/TestProperties';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SendSvg from '../../components/svg/SendSvg';
import SaveSvg from '../../components/svg/SaveSvg';
import ResponseSvg from '../../components/svg/ResponseSvg';
import HttpMethodsMenu from './components/HttpMethodsMenu';
import QueryParamsTable from './components/QueryParamsTable';
import Headers from './components/Headers';
import Authorization from './components/Authorization';
import CookiesTabPage from './components/Cookies';
import QueryBody, { fileParser2 } from './components/QueryBody';
import ResponseEditor from './components/Response';
import ResponseHeader from './components/ResponseHeader';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import GetTestTitle from '../TestPage/components/GetTestTitleModal';
import prettyBytes from 'pretty-bytes';
import { useTestRuns } from '../../data-layer/api-testing';
import { isValidUrl, getJSONResponseFields, getJSONResponseKeys } from './utils';
import TestAssertionss from './components/TestAssertionss';
import NoTestAssertionsSvg from '../../components/svg/NoTestAssertionsSvg';
import {
  AllAssertionResults,
  PassedAssertionResults,
  FailedAssertionResults
} from './components/AssertionResults';
import CustomScrollbar from '../TestPage/components/CustomScrollbar';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import TestFolders from './components/TestFolders';
import useAwaitModal from '../../hooks/useAwaitModal';
import { useFocusedState } from '../../hooks/useFocusedState';
import SaveRequestModal from './components/SaveRequestModal';
import CreateFolder from './components/CreateFolder';
import SaveTestExampleModal from './components/SaveTestExampleModal';
import NoCookiesSvg from '../../components/svg/NoCookiesSvg';
import SavePostmanCollection from './components/SavePostmanCollection';
import FeatureFlagsContext from '../../store/featureFlagsState';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import { VariablesList } from './components/VariablesListWindow';
import { SaveTextAsVariable } from './components/SaveTextAsVariable';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { TestAutomationContext } from '../../store/testAutomationState';
import { convertTimeToSeconds } from '../../utils/time';
import { TestCaseContext } from '../../store/testState';
import { Close } from '@mui/icons-material';
import DeleteSvg from '../../components/svg/DeleteSvg';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import { useDebouncePathVariable, useDebounceSearchValue } from '../../hooks/useDebounce';
import { runAssertions } from './localTestRunUtils';
import ResponseCookies from './components/ResponseCookies';
import BrowserSvg from '../../components/svg/BrowserSvg';
import { SelectTestRunAgent } from './components/TestRunAgentModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { rgba } from '../../utils/colors';
import RenameModal from './components/RenameModal';
import { Console } from './components/Console';
import CreateSystemVariableForApi from './components/ApiCreateSystemVariable';
import { processVariableString, processVariableStringForApi } from '../Mobile/MobileTestPage';
import { parseISO, format } from 'date-fns';
import TestDetails from './components/Toolbar/TestDetails';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';

import ToolBar from './components/Toolbar/Toolbar';
import Documentation from './components/Documentation';
import { generateRandomBoolean } from '../Mobile/utils';
import PathVariablesTable from './components/PathVariables';
import { swapPathVariablesInURL } from './utils';
import { isError } from 'lodash';

const formatDate = (date) => {
  const result = parseISO(date);
  const formattedDate = format(result, 'MMMM d, yyyy, h:mm a OOOO');
  return formattedDate;
};

export const tabStyle = {
  textTransform: 'none',
  textAlign: 'left',
  py: 1,
  fontSize: '0.85rem',
  minHeight: 0,
  maxHeight: 'max-content',
  minWidth: 0,
  maxWidth: 'max-content',
  mr: 6,
  '&: hover': {
    color: '#1958B8'
  }
};
const checkIfAgentIsOn = async (cbOnSuccess, agentPort) => {
  try {
    const result = await fetch(`http://localhost:${agentPort}/api/run-test`);
    if (result.ok) {
      cbOnSuccess();
    }
    if (!result.ok) {
      toast.info('Scandium Desktop Agent is not running on your device', {
        autoClose: 4000
      });
    }
  } catch (err) {
    // console.log(err);
    toast.info('Scandium Desktop Agent is not running on your device');
  }
};
const ChooseRunAgent = ({ displayRunAgentModal }) => {
  const { runAgent, agentPort } = useContext(TestCaseContext);
  return (
    <Tooltip title={runAgent === 'Desktop-Agent' ? `port ${agentPort} ` : ''}>
      <Box
        onClick={() => {
          displayRunAgentModal();
        }}
        sx={{
          // outline: '1.5px solid rgba(160,160,160, 1)',
          borderRadius: 1.5,
          px: 1.5,
          marginLeft: 'auto',
          marginRight: 3,
          py: 0.2,
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        {runAgent === 'Cloud-Agent' && (
          <Flex sx={{ mr: 1 }}>
            <CloudOutlinedIcon color="primary" />
            <Typography color={'primary'}>Cloud Agent</Typography>
            <ArrowDropDownIcon />
          </Flex>
        )}
        {runAgent === 'Browser-Agent' && (
          <Flex>
            {' '}
            <BrowserSvg width={15} height={15} />
            <Typography color={'primary'}>Browser Agent</Typography> <ArrowDropDownIcon />
          </Flex>
        )}
        {runAgent === 'Desktop-Agent' && (
          <Flex>
            <DesktopWindowsOutlinedIcon color="primary" fontSize="12px" />
            <Typography color={'primary'}>Desktop Agent</Typography>
            <ArrowDropDownIcon
              sx={
                {
                  // ml: .5
                }
              }
            />
          </Flex>
        )}
      </Box>
    </Tooltip>
  );
};

export const cleanJson = (jsonString) => {
  try {
    const clean = jsonString.replace(/\n/g, '');
    const jsonObj = JSON.parse(clean);
    return jsonObj;
  } catch (err) {
    return null;
  }
};
export const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index} style={{ width: '100%' }}>
      {value === index && <Box sx={{ minHeight: '300px' }}>{children}</Box>}
    </div>
  );
};

const statusCodeColorMap = {
  2: '#00CA08',
  4: rgba('#EF1B1B', 70)
};
const getStatusText = (statusCode) => {
  const statusMap = {
    200: 'Ok',
    201: 'Created',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    422: 'Unprocessable Entity',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout'
  };

  return statusMap[statusCode] || '';
};

const NewApi = () => {
  const theme = useTheme();
  const [cookies] = useCookies([
    'token',
    'email',
    'name',
    'user',
    'local_features',
    'company',
    'active_company',
    'role',
    'designation'
  ]);
  const variableSuggestionList = useRef(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeProject = useActiveProject();
  const [paramsUpdated, setParamsUpdated] = useState(false);
  const { featureFlags, isFeatureFlagsLoading } = useContext(FeatureFlagsContext);
  const { testId, projectId, folderId, exampleId } = useParams();
  const [testsPage, setTestpage] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [responseTab, setResponseTab] = useState(0);
  const [assertionResultTab, setAssertionResultTab] = useState(0);
  const [title, setTitle] = useState('Untitled test');
  const [url, setUrl] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET');
  const [rawContent, setRawContent] = useState('');
  const [graphqlVariables, setGraphQLVariables] = useState('');
  const [isRunningTestByDesktopAgent, setIsRunningByAgent] = useState(false);
  const [showRunProperty, setShowRunProperty] = useState(true);
  const [queryParams, setQueryParams] = useState([{ key: '', value: '', description: null }]);
  const [requestCookies, setRequestCookies] = useState([{ key: '', value: '', expires_at: '' }]);
  const [queryHeaders, setQueryHeaders] = useState([{ key: '', value: '', description: null }]);
  const [authorizationHeader, setAuthorizationHeader] = useState({
    option: 'no-auth',
    bearer: '',
    basic: { username: '', password: '' },
    apiKey: { key: '', value: '', path: 'headers' },
    digestAuth: {
      username: '',
      password: '',
      realm: '',
      nonce: '',
      algorithm: 'MD5',
      qop: '',
      nc: '',
      cnonce: '',
      opaque: '',
      retry: true
    },
    oauth1: {
      oauth_callback: '',
      oauth_consumer_key: '',
      oauth_consumer_secret: '',
      oauth_nonce: '',
      oauth_signature: '',
      oauth_signature_method: 'HMAC-SHA1',
      oauth_timestamp: '',
      oauth_token: '',
      oauth_token_secret: '',
      oauth_verifier: '',
      oauth_version: '',
      realm: '',
      addEmptyFields: false,
      path: 'headers'
    },
    oauth2: {
      access_token: '',
      header_prefix: '',
      token_name: '',
      grant_type: '',
      callback_url: '',
      auth_url: '',
      access_token_url: '',
      client_ID: '',
      client_secret: '',
      scope: '',
      state: '',
      client_authentication: 'body',
      resource: [],
      audience: [],
      path: 'headers'
    }
  });
  useDebouncePathVariable(500, url);
  const [apiResponse, setApiResponse] = useState('');
  const [showResponseProps, setShowResponseProps] = useState(false);
  const [responseHeaders, setResponseHeaders] = useState([]);
  const [responseCookies, setResponseCookies] = useState([]);
  const [timeTaken, setTimeTaken] = useState(0);
  const [savedTest, setSavedTest] = useState(null);
  const [openTitleModal, setOpenTitleModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [size, setSize] = useState('0');
  const responseRef = useRef(null);
  const [debouncedUrlString] = useDebounce(url, 1200);
  const [runsResult, setRunsResult] = useState(null);
  // variableServerState this denotes the true state gotten from the server before changes are made locally,
  //other components are able to access this true state without the values being compromised by components which edits or changes this state locally
  const [variablesServerState, setVariableServerState] = useState([]);
  const [globalVariableState, setGlobalVariableState] = useState({
    openModal: false,
    globalVariables: [],
    useCase: 'add_variables'
  });
  const [assertStatusCode, setAssertStatusCode] = useState({});
  const [assertBodyType, setAssertBodyType] = useState({});
  const [assertBodyContent, setAssertBodyContent] = useState([]);
  const [assertHeaders, setAssertHeaders] = useState([]);
  const [assertDuration, setAssertDuration] = useState({});
  const [assertionsResults, setAssertionsResults] = useState({});
  const [assertJSONResponseField, setAssertJSONResponseField] = useState([]);
  const [dropdowns, setDropdowns] = useState([]);
  const [requestBodyType, setRequestBodyType] = useState('none');
  const [formData, setFormData] = useState([
    { key: '', value: '', type: 'text', filename: '', description: '' }
  ]);
  const [formUrlEncoded, setFormUrlEncoded] = useState([{ key: '', value: '', description: '' }]);
  const [language, setLanguage] = useState('json');
  const [responseEditorState, setResponseEditorState] = useState({
    wordWrap: 'on',
    responseEditorLanguage: 'json',
    lineNumbers: 'on',
    value: '',
    activeBTN: 'pretty'
  });
  const [responseFile, setResponseFile] = useState({
    isFile: false,
    fileType: null, // pdf ? images? e.t.c
    fileBase64: null,
    blobFromLocalRun: ''
  });

  const readyOnly = activeProject?.read_only;

  const handleResponseEditorStateChange = (newState) => {
    setResponseEditorState({ ...responseEditorState, ...newState });
  };

  const scrollToResponse = () => {
    responseRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const {
    clickedTest,
    clickedExample,
    clickedFolder,
    setClickedTest,
    setClickedFolder,
    massSelection,
    setMassSelection,
    searchValue,
    setSearchValue,
    localRun,
    localMode,
    setLocalMode,
    setLocalRun,
    showLocalModeNotice,
    setShowLocalModeNotice,
    runAgent,
    setRunAgent,
    agentPort,
    initialTestProps,
    resetInitialTestProps,
    isTestChanges,
    setIsTestChanges,
    setConsoleData,
    setTestDetails,
    testDocumentData,
    setTestDocumentData,
    setFoldersState,
    testCaseData: { pathVariables },
    setTestCaseData
    // setInitialTestState
  } = useContext(TestCaseContext);
  const {
    showVariableFloatingWindow,
    setVariableFloatingWindow,
    setSaveHighLightedTextState,
    setJSONResponseFields,
    openDynamicValues,
    setOpenDynamicValues,
    cursorPosition,
    setCursorPosition,
    setFocusedInputPosition,
    focusedInput
  } = useContext(FloatingVariableWindowContext);
  const resetInitialState = () => {
    setShowResponseProps(false);
    setClickedTest('');
    setClickedFolder('');
    setShowLocalModeNotice(false);
    setParamsUpdated(false);
    resetInitialTestProps();
    setSelectedTab(0);
    setResponseTab(0);
    setAssertionResultTab(0);
    setTitle('Untitled test');
    setUrl('');
    setHttpMethod('GET');
    setRawContent('');
    setQueryParams([{ key: '', value: '', description: null }]);
    setQueryHeaders([{ key: '', value: '', description: null }]);
    setApiResponse('');
    setResponseHeaders({});
    setAuthorizationHeader({
      option: 'no-auth',
      bearer: '',
      basic: { username: '', password: '' },
      apiKey: { key: '', value: '', path: 'headers' },
      digestAuth: {
        username: '',
        password: '',
        realm: '',
        nonce: '',
        algorithm: 'MD5',
        qop: '',
        nc: '',
        cnonce: '',
        opaque: '',
        retry: true
      },
      oauth1: {
        oauth_callback: '',
        oauth_consumer_key: '',
        oauth_consumer_secret: '',
        oauth_nonce: '',
        oauth_signature: '',
        oauth_signature_method: 'HMAC-SHA1',
        oauth_timestamp: '',
        oauth_token: '',
        oauth_token_secret: '',
        oauth_verifier: '',
        oauth_version: '',
        realm: '',
        addEmptyFields: false,
        path: 'headers'
      },
      oauth2: {
        access_token: '',
        header_prefix: '',
        token_name: '',
        grant_type: '',
        callback_url: '',
        auth_url: '',
        access_token_url: '',
        client_ID: '',
        client_secret: '',
        scope: '',
        state: '',
        client_authentication: 'body',
        resource: [],
        audience: [],
        path: 'headers'
      }
    });
    setSavedTest(null);
    setOpenTitleModal(false);
    setStatus(null);
    setSize('0');
    responseRef.current = null;
    setRunsResult(null);
    setResponseFile({
      isFile: false,
      fileType: null,
      fileBase64: null
    });
    setAssertDuration({});
    setAssertStatusCode({});
    setAssertBodyType({});
    setAssertBodyContent([]);
    setAssertHeaders([]);
    setAssertJSONResponseField([]);
    setAssertionsResults({});
    setDropdowns([]);
    setRequestBodyType('none');
    setFormData([{ key: '', value: '', description: null }]);
    setFormUrlEncoded([{ key: '', value: '', description: '' }]);
    setFormData([{ key: '', value: '', type: 'text', description: null }]);
    setLanguage('json');
    setGraphQLVariables('');
    setResponseEditorState({
      wordWrap: 'on',
      responseEditorLanguage: 'json',
      lineNumbers: 'on',
      value: '',
      activeBTN: 'pretty'
    });
    setTestCaseData((prev) => {
      return { ...prev, pathVariables: [] };
    });
    setSaveHighLightedTextState((prev) => {
      return { ...prev, highLightedValue: '', showModal: false };
    });
    setMassSelection({
      active: false,
      selectedApiTests: [],
      selectedApiTestExamples: [],
      selectedFolders: [],
      selectedSuites: []
    });
    setTestDocumentData({
      url: '',
      method: '',
      reqParams: [],
      reqHeaders: [],
      reqBody: '',
      mode: '',
      authType: ''
    });
  };
  const getRequestBody = () => {
    const _formData = formData?.filter((entry) => entry.key && entry.value);
    const _formUrlEncoded = formUrlEncoded?.filter((entry) => entry.key && entry.value);
    let requestBody = null;
    if (requestBodyType === 'urlencoded' && _formUrlEncoded.length > 0) {
      requestBody = _formUrlEncoded;
    } else if (requestBodyType === 'formData' && _formData.length > 0) {
      requestBody = _formData;
    } else if (requestBodyType === 'raw') {
      if (language === 'json') {
        requestBody = cleanJson(rawContent);
      } else if (language === 'graphql') {
        requestBody = {};
        requestBody.query = rawContent;
        requestBody.variables = cleanJson(graphqlVariables) || {};
      } else {
        requestBody = rawContent;
      }
    } else if (requestBodyType === 'none') {
      requestBody = '';
    }
    return requestBody;
  };
  const { handleVariableFloatingWindow } = useFocusedState();
  const { debounceSearchValue } = useDebounceSearchValue();
  const [
    requestTestRunAgentModal,
    {
      open: openTestRunAgentModal,
      onClose: onCloseTestRunAgentModal,
      onComplete: completeTestRunAgentModal
    }
  ] = useAwaitModal();
  const [
    {
      open: saveRequestModal,
      onClose: onCloseSaveRequestModal,
      onComplete: completeSaveRequestModal
    }
  ] = useAwaitModal();
  const { requestConfirm: requestTestDeleteConfirm, ConfirmationDialog: DeleteConfirmationDialog } =
    useConfirmDialog();
  const {
    requestConfirm: requestExampleDeleteConfirm,
    ConfirmationDialog: TestExampleDeleteConfirmationDialog
  } = useConfirmDialog();
  const {
    requestConfirm: requestFolderDeleteConfirm,
    ConfirmationDialog: FolderDeleteConfirmationDialog
  } = useConfirmDialog();
  const {
    requestConfirm: requestConfirmDuplicate,
    ConfirmationDialog: ConfirmationDuplicateDialog
  } = useConfirmDialog();
  const {
    requestConfirm: requestSaveChangesDialog,
    ConfirmationDialog: SaveChangesDialogNavToTest
  } = useConfirmDialog();
  const {
    requestConfirm: requestSaveChangesDialogNavToNew,
    ConfirmationDialog: SaveChangesDialogNavToNew
  } = useConfirmDialog();
  const { requestConfirm: confirmTestsDeletion, ConfirmationDialog: DeleteSelectedApiTests } =
    useConfirmDialog();
  const [
    requestSaveTestExampleModal,
    {
      open: openSaveTestExampleModal,
      onClose: onCloseSaveTestExampleModal,
      onComplete: completeSaveTestExampleModal
    }
  ] = useAwaitModal();

  const [
    requestImportPostmanCollectionModal,
    {
      open: openImportPostmanCollectionModal,
      onClose: onCloseImportPostmanCollectionModal,
      onComplete: completeImportPostmanCollectionModal
    }
  ] = useAwaitModal();
  useEffect(() => {
    const clickListener = (e) => {
      if (!!showVariableFloatingWindow) {
        if (!variableSuggestionList.current.contains(e.target)) {
          setVariableFloatingWindow(false);
        }
      }
    };
    window.addEventListener('click', clickListener);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, [showVariableFloatingWindow]);

  const [
    requestCreateFolderModal,
    {
      open: openCreateFolderModal,
      onClose: onCloseCreateFolderModal,
      onComplete: completeCreateFolderModal
    }
  ] = useAwaitModal();
  const [
    requestRenameFolderModal,
    {
      open: openRenameFolderModal,
      onClose: onCloseRenameFolderModal,
      onComplete: completeRenameFolderModal
    }
  ] = useAwaitModal();
  const [
    requestRenameTestModal,
    {
      open: openRenameTestModal,
      onClose: onCloseRenameTestModal,
      onComplete: completeRenameTestModal
    }
  ] = useAwaitModal();
  const handleSuggestionForLocalMode = (message) => {
    setShowLocalModeNotice(true);
  };
  const isAssertionResultsNotNull = useMemo(() => {
    return Object.keys(assertionsResults).length > 0
      ? Object.values(assertionsResults).some((value) => value !== null)
      : false;
  }, [assertionsResults]);
  const handleOperatorSelect = (index, value) => {
    const initialValue = dropdowns[index].selectedOperator;
    const assertType = dropdowns[index].selectedOption;
    // update the dropdown version
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = {
      ...updatedDropdowns[index],
      selectedOperator: value
      // range: ''
      //dont know remember why i want the range cleared when an operator changes, no thing seems broken a.t.m
    };
    if (assertType === 'response body type') {
      if (initialValue === 'one_of') {
        updatedDropdowns[index] = {
          ...updatedDropdowns[index],
          value: ''
        };
      }
      if (value === 'one_of') {
        updatedDropdowns[index] = {
          ...updatedDropdowns[index],
          value: updatedDropdowns[index].oneOf
        };
      }
    }
    setDropdowns(updatedDropdowns);
  };
  //this handles updating only 'status code's oneOf operator, response body tpe is handled by a textFieldChange function
  const handleOneOfInsertions = (index, value) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index].oneOf.push(value);
    setDropdowns(updatedDropdowns);

    setAssertStatusCode((prev) => {
      const update = { ...prev, value: updatedDropdowns[index].oneOf };
      return update;
    });
  };
  const handleCreateVariableFromJson = (index, field, value) => {
    const updatedDropdowns = [...dropdowns];
    if (field === 'variableName') updatedDropdowns[index].value[field] = value.trim();
    else if (field === 'value') {
      updatedDropdowns[index].value[field] = value.slice(8).trim();
    } else {
      updatedDropdowns[index].value[field] = value;
    }

    setDropdowns(updatedDropdowns);
  };
  const handleAssertHeaderValue = (index, field, value) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index].value[field] = value.trim();
    setDropdowns(updatedDropdowns);
  };
  const handleRangeLimit = (index, value, position) => {
    const updatedDropdowns = [...dropdowns];
    let newRange = updatedDropdowns[index].range;
    if (newRange === '') {
      newRange = [];
    }
    newRange[position] = value;
    updatedDropdowns[index] = {
      ...updatedDropdowns[index],
      range: newRange
    };
    if (position === 0 && !!dropdowns[index].range[1]) {
      if (Number(value) > Number(dropdowns[index].range[1])) {
        if (!toast.isActive('range-warning'))
          toast.warning(`lower limit value should NOT exceed upper limit value`, {
            toastId: 'range-warning',
            autoClose: 2000,
            hideProgressBar: true
          });
      }
    }
    if (position === 1 && !!dropdowns[index].range[0]) {
      if (Number(value) < Number(dropdowns[index].range[0])) {
        if (!toast.isActive('range-warning'))
          toast.warning(`upper limit value SHOULD exceed lower limit value`, {
            toastId: 'range-warning',
            autoClose: 2000,
            hideProgressBar: true
          });
      }
    }

    if (position === 0 && !!dropdowns[index].range[1]) {
      if (Number(value) > Number(dropdowns[index].range[1])) {
        if (!toast.isActive('range-warning'))
          toast.warning(`lower limit value should NOT exceed upper limit value`, {
            toastId: 'range-warning',
            autoClose: 2000,
            hideProgressBar: true
          });
      }
    }
    if (position === 1 && !!dropdowns[index].range[0]) {
      if (Number(value) < Number(dropdowns[index].range[0])) {
        if (!toast.isActive('range-warning'))
          toast.warning(`upper limit value SHOULD exceed lower limit value`, {
            toastId: 'range-warning',
            autoClose: 2000,
            hideProgressBar: true
          });
      }
    }

    setDropdowns(updatedDropdowns);
  };
  const handleOptionSelect = (index, value) => {
    const configValue = () => {
      let _value = '';
      if (value === 'create global variable') {
        _value = { variableName: '', value: '', cascade: true };
      }
      if (value === 'response header') {
        _value = { key: '', value: '' };
      }
      return _value;
    };
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = {
      ...updatedDropdowns[index],
      selectedOption: value,
      value: configValue(),
      selectedOperator: value !== 'request duration' ? 'equal' : 'less_than',
      range: [],
      oneOf: []
    };
    setDropdowns(updatedDropdowns);
  };
  // { selectedOption: '', selectedOperator: '', range: [], oneOf: [] }
  const handleAddButtonClick = () => {
    setDropdowns((prev) => {
      return [
        ...prev,
        { selectedOption: '', selectedOperator: '', range: [], oneOf: [], value: '' }
      ];
    });
  };

  const handleTextFieldChange = (index, value) => {
    setDropdowns((prevDropdowns) => {
      const updatedDropdowns = [...prevDropdowns];
      const selectedOperator = updatedDropdowns[index]?.selectedOperator;
      updatedDropdowns[index] = { ...updatedDropdowns[index], value };
      if (selectedOperator === 'one_of') {
        updatedDropdowns[index] = { ...updatedDropdowns[index], oneOf: value };
      } else {
        updatedDropdowns[index] = { ...updatedDropdowns[index], value };
      }
      const selectedOption = updatedDropdowns[index]?.selectedOption;
      const selectedRange = updatedDropdowns[index]?.range;
      switch (selectedOption) {
        case 'status code':
          setAssertStatusCode((prevStatusCodes) => {
            let updatedStatusCodes = { ...prevStatusCodes };
            updatedStatusCodes = {
              operator: selectedOperator,
              position: index,
              value: selectedOperator !== 'range' ? value : selectedRange
            };
            return updatedStatusCodes;
          });
          break;
        case 'response header':
          const headerKey = updatedDropdowns[index]?.headerKey || '';
          setAssertHeaders((prevResponseHeaders) => {
            const updatedResponseHeaders = [...prevResponseHeaders];
            updatedResponseHeaders[index] = { key: headerKey, value };
            return updatedResponseHeaders;
          });
          break;
        case 'response body type':
          setAssertBodyType((prevResponseBodyType) => {
            const updatedResponseBodyType = { ...prevResponseBodyType };
            updatedResponseBodyType.value = value;
            updatedResponseBodyType.position = index;
            return updatedResponseBodyType;
          });
          break;
        case 'response body content':
          setAssertBodyContent((prevResponseBodyContent) => {
            const updatedResponseBodyContent = [...prevResponseBodyContent];
            updatedResponseBodyContent[index] = { value, operator: selectedOperator };
            return updatedResponseBodyContent;
          });
          break;
        case 'request duration':
          setAssertDuration((prevRequestDuration) => {
            let updatedRequestDuration = { ...prevRequestDuration };
            updatedRequestDuration.position = index;
            updatedRequestDuration.value = selectedOperator !== 'range' ? value : selectedRange;
            return updatedRequestDuration;
          });
          break;
        default:
          break;
      }
      return updatedDropdowns;
    });
  };

  const handleDeleteButtonClick = (index) => {
    setDropdowns((prevDropdowns) => {
      const updatedDropdowns = [...prevDropdowns];
      updatedDropdowns.splice(index, 1);
      return updatedDropdowns;
    });
    // THESE CAN HAVE ONLY ONE INSTANCE
    if (dropdowns[index].selectedOption === 'status code') {
      setAssertStatusCode({});
    }

    if (dropdowns[index].selectedOption === 'request duration') {
      setAssertDuration({});
    }
    if (dropdowns[index].selectedOption === 'response body type') {
      setAssertBodyType({});
    }
    //------------------------------
    setAssertHeaders((prevAssertHeaders) => {
      const updatedAssertHeaders = [...prevAssertHeaders];
      updatedAssertHeaders.splice(index, 1);
      return updatedAssertHeaders;
    });
    setAssertJSONResponseField((prevFields) => {
      const updatedJSONResponseFields = [...prevFields];
      updatedJSONResponseFields.splice(index, 1);
      return updatedJSONResponseFields;
    });
  };
  useDocumentTitle(testId ? `Edit Test${savedTest?.id ? `: ${savedTest.name}` : ''}` : 'New API');
  const fetchRequest = !!testId && !exampleId;
  const fetchExample = !!exampleId && !!testId;
  const showSaveResponseBTN = !!testId && !exampleId && showRunProperty;
  const {
    isSuccess: singleTestFetched,
    isLoading: isLoadingTest,
    isFetching: isFetchingTest,
    error: testsError,
    refetch: refetchActiveTest
  } = useScandiumQuery(
    `/projects/${projectId}/api-tests/${exampleId ? `${testId}/examples/${exampleId}` : testId}`,
    {
      enabled: !!activeProject?.id && (fetchExample || fetchRequest),
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        let documentData = {};
        {
          if (!data.data.uuid) {
            setTestDetails({
              ID: data?.data?.id,
              'Created on': formatDate(data?.data?.created_at),
              'Updated on': formatDate(data?.data?.updated_at),
              Creator: data?.data?.creator?.name
            });
          }
        }
        setIsTestChanges(false);
        setSelectedTab(1);
        setSelectedTab(0);
        setShowRunProperty(true);
        let fileType;
        let isFileData = false;
        const _rawTypes = ['plaintext', 'json', 'javascript', 'html', 'xml', 'graphql'];
        const _otherBodyTypes = ['urlencoded', 'formData'];
        const expectedMime = [
          'image/jpeg',
          'image/png',
          'application/pdf',
          'image',
          'jpeg',
          'png',
          'pdf'
        ];
        const _data = data.data;
        if (_data.response) {
          setShowResponseProps(true);
          setTimeTaken(_data.response.time_taken);
        }
        if (!!_data.response?.headers) {
          documentData.reqHeaders = _data.response?.headers.filter(
            (header) => !!header.key && !!header.value
          );
          // response headers is an array type for scandium collection but an object type for postman.
          if (Array.isArray(_data.response?.headers)) {
            _data.response?.headers.forEach((header) => {
              if (
                header.key.toLowerCase() === 'content-type' &&
                expectedMime.includes(header.value)
              ) {
                fileType = header.value;
                isFileData = true;
              }
            });
          }
          // for postman headers (object type)
          else if (!Array.isArray(_data.response?.headers)) {
            Object.entries(_data.response?.headers).map(([key, value]) => {
              if (key === 'Content-Type' && expectedMime.includes(value[0])) {
                fileType = value[0];
                isFileData = true;
              }
            });
          }
        }
        if (_data.data?.mode) {
          documentData.reqBody = _data.data.body;
          documentData.mode = _data.data?.mode;
          if (_rawTypes.includes(_data.data.mode)) {
            setRequestBodyType('raw');
            setLanguage(_data.data.mode);
            documentData.reqBody = _data.data.body;
            if (_data.data.mode === 'json') {
              let jsObjectBody = _data?.data?.body;
              if (typeof jsObjectBody === 'object') {
                //if body is js Object
                setRawContent(_data.data.body ? JSON.stringify(data.data.data.body, null, 2) : '');
              } else {
                // if body is json string
                setRawContent(_data.data.body ? data.data.data.body : '');
              }
            } else if (_data.data.mode === 'graphql') {
              setRawContent(_data.data.body ? _data.data.body.query : '');
              setGraphQLVariables(
                _data.data.body ? JSON.stringify(_data.data.body.variables, null, 2) : ''
              );
            } else {
              setRawContent(_data.data.body ? _data.data.body : '');
            }
          } else if (_otherBodyTypes.includes(_data.data.mode)) {
            setRequestBodyType(_data.data.mode);
            if (_data.data.mode === 'formData') {
              setFormData((prev) => {
                if (_data.data.body.length > 0) {
                  return [
                    ..._data.data.body,
                    { key: '', value: '', type: 'text', filename: '', description: '' }
                  ];
                } else {
                  return prev;
                }
              });
            }
            if (_data.data.mode === 'urlencoded') {
              setFormUrlEncoded((prev) => {
                if (_data.data.body.length > 0) {
                  return [..._data.data.body, { key: '', value: '', description: '' }];
                } else {
                  return prev;
                }
              });
            }
          }
        }
        //------------------------------------------------------------------------
        // aligning with post man import document structure
        if (_data.data.body?.mode) {
          documentData.mode = _data.data?.body?.mode;
          const _request_body = _data.data?.body;
          setRequestBodyType(_request_body?.mode);
          setLanguage(_request_body.options ? _request_body.options.raw.language : 'json');
          if (!!_request_body.options?.raw) {
            documentData.mode = _request_body.options.raw.language;
          }

          // setRawContent(_request_body.raw ? JSON.stringify(_request_body.raw, null, 2) : '');
          _request_body.options?.raw?.language === 'json'
            ? setRawContent(JSON.stringify(cleanJson(_request_body?.raw)))
            : setRawContent(_request_body?.raw ? JSON.stringify(_request_body?.raw, null, 2) : '');
          //solve for formData and Urlencoded
          _request_body.mode === 'urlencoded' &&
            setFormUrlEncoded((prev) => {
              return [..._request_body.urlencoded, [...prev]];
            });
          if (_request_body.mode === 'formdata') {
            const newFormData = _request_body.formdata.map((entry) => {
              return { key: entry.key, type: entry.type, value: entry.value || '' };
            });
            setFormData((prev) => {
              return [...newFormData, [...prev]];
            });
          }
        }
        if (!!_data.data.authorization) {
          if (typeof _data.data.authorization === 'object') {
            return;
          } else {
            const authObj = JSON.parse(_data.data.authorization);
            documentData.authType = authObj?.value?.option;
            setAuthorizationHeader((prev) => {
              return { ...prev, ...authObj.value };
            });
          }
        }
        setSavedTest(_data);
        setTitle(_data.name);
        // !!_data.data.url && setUrl(_data.data.url);
        !!_data.data.url ? setUrl(_data.data.url) : setUrl(' ');
        documentData.url = _data?.data?.url;
        setShowLocalModeNotice(!!_data.data.url.includes('localhost') ? true : false);
        let reqMethod = _data?.data?.request_method?.toUpperCase();
        setHttpMethod(reqMethod);
        documentData.method = reqMethod;
        {
          _data?.data?.headers &&
            setQueryHeaders([..._data?.data?.headers, { key: '', value: '', description: null }]);
        }
        documentData.reqHeaders = _data?.data?.headers;
        {
          _data?.data?.params &&
            setQueryParams([..._data?.data?.params, { key: '', value: '', description: null }]);
          documentData.reqParams = _data?.data?.params?.filter(
            (param) => !!param.key && !!param.value
          );
        }
        {
          _data?.data?.cookies &&
            setRequestCookies([..._data?.data?.cookies, { key: '', value: '', expires_at: '' }]);
        }
        if (_data?.data?.pathVariables) {
          let newPathVariables = _data.data.pathVariables.map((item) => {
            return {
              ...item,
              value: item.value === null ? '' : item.value,
              description: item.description === null ? '' : item.description
            };
          });
          setTestCaseData((prev) => {
            return { ...prev, pathVariables: newPathVariables };
          });
        }
        setTestDocumentData(documentData);
        if (!!_data?.response) {
          // let _body;
          let bodyType;
          if (!Array.isArray(_data.response?.headers)) {
            const arrayHeaders = Object.entries(_data.response?.headers).map(([key, value]) => {
              return { key: key, value: value[0] };
            });
            _data.response.headers = arrayHeaders;
          }
          if (Array.isArray(_data.response?.headers)) {
            _data.response?.headers.map((header) => {
              if (header.key.toLowerCase() === 'content-type') {
                bodyType = header.value.split(';')[0].split('/')[1];
              }
            });
          }
          bodyType === 'plain' && (bodyType = 'plaintext');
          // if (bodyType === 'json') {
          //   _body = cleanJson(_data.response.body);
          //   setJSONResponseFields({
          //     keys: getJSONResponseKeys(_body),
          //     fields: getJSONResponseFields(_body)
          //   });
          // } else {
          //   _body = _data.response.body;
          // }
          const _size = prettyBytes(JSON.stringify(_data.response.headers)?.length);
          setSize(_size);

          if (_data?.response.size) {
            setSize(prettyBytes(+_data?.response?.size));
          } else if (!_data?.response?.size) {
            const lengthHeader = _data.response.headers.find((header) => {
              return header.key.toLowerCase() === 'content-length';
            });
            if (!!lengthHeader) {
              setSize(prettyBytes(+lengthHeader.value));
            }
          }
          _data.data.cookies?.length > 0 && setRequestCookies(_data.data.cookies);
          setStatus(_data.response.status_code);
          // setApiResponse(_body);
          // bodyType === 'json'
          //   ? handleResponseEditorStateChange({ value: JSON.stringify(_body, null, 2) })
          //   : handleResponseEditorStateChange({ value: _body, responseEditorLanguage: bodyType });
          setResponseTab(2);
          setResponseHeaders(_data.response.headers);
          if (_data.response.assertions) {
            setAssertionsResults(_data.response.assertions);
          }
        }
        if (!!_data.assertions?.length) {
          const newDropdowns = [];
          _data.assertions.map((assertion, index) => {
            if (assertion.name === 'status_code') {
              const assertionOperator = assertion.operator;
              const assertionValue = assertion.value;
              newDropdowns.push({
                selectedOption: 'status code',
                selectedOperator: assertion.operator || '',
                oneOf: assertionOperator === 'one_of' ? assertionValue : [],
                range: assertionOperator === 'range' ? assertionValue : [],
                value:
                  assertionOperator !== 'one_of' && assertionOperator !== 'range'
                    ? assertionValue
                    : ''
              });
            } else if (assertion.name === 'response_body_type') {
              const assertionOperator = assertion.operator;
              const assertionValue = assertion.value;
              newDropdowns.push({
                selectedOption: 'response body type',
                selectedOperator: assertion.operator || '',
                oneOf: assertionOperator === 'one_of' ? assertionValue : [],
                range: [],
                value: assertionOperator !== 'one_of' ? assertionValue : ''
              });
            } else if (assertion.name === 'request_duration') {
              const assertionOperator = assertion.operator;
              const assertionValue = assertion.value;
              newDropdowns.push({
                selectedOption: 'request duration',
                selectedOperator: assertion.operator || '',
                range: assertionOperator === 'range' ? assertionValue : [],
                oneOf: [],
                value: assertionOperator !== 'range' ? assertionValue : ''
              });
            } else if (assertion.name === 'response_body_content') {
              assertion.value.map((value, index) => {
                newDropdowns.push({
                  selectedOption: 'response body content',
                  selectedOperator: value.operator,
                  range: [],
                  oneOf: [],
                  value: value.value
                });
              });
            } else if (assertion.name === 'response_headers') {
              assertion.value.map((entry, index) => {
                newDropdowns.push({
                  selectedOption: 'response header',
                  range: [],
                  oneOf: [],
                  value: entry
                });
              });
            } else if (assertion.name === 'create_global_variable') {
              assertion.value.map((entry, index) => {
                let newEntry = {};
                newEntry.variableName = entry.variableName;
                if (!entry.value.startsWith('[')) {
                  newEntry.value = `.${entry.value}`;
                } else {
                  newEntry.value = entry.value;
                }
                newDropdowns.push({
                  selectedOption: 'create global variable',
                  selectedOperator: 'equal',
                  range: [],
                  oneOf: [],
                  value: { ...newEntry, cascade: entry?.cascade }
                });
              });
            }
          });
          setDropdowns(newDropdowns);
        }
      }
    }
  );

  const {
    isSuccess: fetchedFoldersAndTests,
    data: allFolders = { folders: { data: [] }, test_cases: { data: [] } },
    isLoading: isFetchingAllFoldersAndTests,
    error: folderAndTestErrors,
    refetch: refetchFoldersAndTests
  } = useScandiumQuery(
    `/projects/${activeProject?.id}/api-tests/all/?search=${debounceSearchValue}`,
    {
      queryKey: ['get-apiFoldersAndTests', debounceSearchValue],
      enabled: !!activeProject?.id,
      select: (data) => data.data,
      onSuccess: (data) => {
        // console.log(data);
        setFoldersState((prev) => {
          return { ...prev, foldersList: data?.folders?.data };
        });
      }
    }
  );
  const { data: activeFolder } = useScandiumQuery(
    `/projects/${activeProject?.id}/folders/${folderId}`,
    {
      queryKey: ['get-active-folder'],
      enabled: !!activeProject?.id && !!folderId,
      select: (data) => data.data
    }
  );
  ///isFetching captures background requests while is loading does not
  const isPageLoading = isFetchingTest;
  const { mutateAsync: saveTest, isLoading: isSavingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${testId ? `${testId}` : ''}`,
    {
      method: !!testId ? 'PUT' : 'POST',
      onSuccess: (data) => {
        setTestDetails({
          ID: data?.data?.id,
          'Created on': formatDate(data?.data?.created_at),
          'Updated on': formatDate(data?.data?.updated_at),
          Creator: data?.data?.creator?.name
        });
        // console.log(getRequestBody());
        setTestDocumentData({
          url: url,
          method: httpMethod,
          reqParams: queryParams.filter((param) => param.src !== 'authorization' && !!param.key),
          reqHeaders: queryHeaders.filter(
            (param) => !!param.key && !!param.value && param.src !== 'authorization'
          ),
          mode: requestBodyType === 'raw' ? language : requestBodyType,
          authType: authorizationHeader?.option,
          reqBody: getRequestBody()
        });
        setIsTestChanges(false);
        refetchFoldersAndTests();
        handleInitialTestProps();
        trackMixPanel(TRACKING_IDS.TEST_SAVED, {
          type: 'api'
        });
      }
    }
  );
  const handleNavToNewAfterSavingChanges = () => {
    resetInitialState();
    navigate(`/projects/${activeProject?.id}/api-suites/new`);
    return;
  };
  const handleNavToTestAfterSavingChanges = () => {
    if (testId !== clickedTest.id) {
    }
    resetInitialState();
    // Use the navigate function to update the URL
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${clickedTest.id}/edit` : `${clickedTest.id}/edit`
      }`
    );
  };
  const handleInitialTestProps = () => {
    initialTestProps.current = JSON.stringify({
      requestBodyType,
      url,
      language,
      dropdowns,
      rawContent,
      authorizationHeader,
      queryParams: JSON.stringify(queryParams).replace(/null/g, '""'),
      queryHeaders: JSON.stringify(queryHeaders).replace(/null/g, '""'),
      formData: JSON.stringify(formData).replace(/null/g, '""'),
      formUrlEncoded: JSON.stringify(formUrlEncoded).replace(/null/g, '""'),
      requestCookies,
      runsResult,
      pathVariables
    });
  };
  useEffect(() => {
    if (!!testId) {
      //dont set initial state until params has been updated
      if (!paramsUpdated) {
        return;
      }
      if (!!singleTestFetched) {
        // console.log('fetched test, state set');
        handleInitialTestProps();
      }
    } else {
      handleInitialTestProps();
      // console.log('initial state set');
    }
  }, [singleTestFetched, paramsUpdated, testId]);
  useEffect(() => {
    const checkForChanges = () => {
      if (!initialTestProps.current || !!isFetchingTest) {
        return;
      }
      const currentData = JSON.stringify({
        requestBodyType,
        url,
        language,
        dropdowns,
        rawContent,
        authorizationHeader,
        queryParams: JSON.stringify(queryParams).replace(/null/g, '""'),
        queryHeaders: JSON.stringify(queryHeaders).replace(/null/g, '""'),
        formData: JSON.stringify(formData).replace(/null/g, '""'),
        formUrlEncoded: JSON.stringify(formUrlEncoded).replace(/null/g, '""'),
        requestCookies,
        runsResult,
        pathVariables
      });
      if (initialTestProps.current === currentData) {
        !!isTestChanges && setIsTestChanges(false);
        // console.log('values unchanged');
      } else {
        !isTestChanges && setIsTestChanges(true);
        // console.log('values changed');
      }
    };
    checkForChanges();
  }, [
    requestBodyType,
    debouncedUrlString,
    url,
    language,
    dropdowns,
    rawContent,
    authorizationHeader,
    queryParams,
    queryHeaders,
    formData,
    formUrlEncoded,
    requestCookies,
    pathVariables
  ]);
  const { mutateAsync: deleteFolder, isLoading: isDeletingFolder } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/${clickedFolder.id}`,
    {
      enabled: !!activeProject?.id,
      method: 'DELETE',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success('Folder deleted successfully');
        refetchFoldersAndTests();

        if (folderId === data.id) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      }
    }
  );
  const { mutateAsync: DuplicateTest, isLoading: isDuplicating } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest.id}/duplicate`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  const { mutateAsync: deleteTestExample, isLoading: isDeletingExample } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${testId}/examples/${clickedExample?.uuid}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
        if (exampleId === clickedExample?.uuid) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  const { mutateAsync: deleteTest, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest.id}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
        // Check if the deleted testId matches the testId from the URL, if it does, navigate to the api base url
        if (testId === clickedTest.id) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  const { mutateAsync: massDelete, isLoading: isMassDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/multi-delete`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['get-apiTests'] });
        queryClient.invalidateQueries({ queryKey: ['get-apiFolders'] });
        toast.success(`${data.message}`, { autoClose: 800 });
        if (!!massSelection.selectedApiTests.includes(testId)) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
          return;
        }
        setMassSelection({
          active: false,
          selectedApiTests: [],
          selectedApiTestExamples: [],
          selectedFolders: [],
          selectedSuites: []
        });
      },
      onError: (error) => {
        toast.error(error?.message, {
          autoClose: 800
        });
      }
    }
  );

  const { mutateAsync: _runTest, isLoading: isRunningTest } = useTestRuns({
    onSuccess: (data) => {
      // console.log('got here success');
      setShowResponseProps(true);
      setShowRunProperty(true);
      setResponseTab(0);
      let bodyType = 'plaintext';
      // handle status code zeros
      let response = data.data;
      if (!response.body) {
        response.body = 'No response body from server';
      }
      if (!response.headers) {
        response.headers = [];
      }
      if (!response.assertions) {
        response.assertions = {};
      }
      if (!response.cookies) {
        response.cookies = [];
      }
      const requestData = response?.request_data;
      const logErrors = response?.log_error;
      if (!!logErrors) {
        if (!!logErrors?.length) requestData.log_error = logErrors;
      }
      if (response.status_code === 0) {
        requestData.isError = true;
      }
      setConsoleData((prev) => [...prev, requestData]);
      const checkContentType = response?.headers?.find((header) => {
        return header.key.toLowerCase() === 'content-type';
      });
      let cookies = response?.headers?.find((header) => {
        return header.key.toLowerCase() === 'set-cookie';
      });
      if (!!cookies) {
        let splitValues = cookies.value.split(';');
        let cookiesArray = [];
        splitValues.forEach((cookieField) => {
          const [key, value] = cookieField.split('=');
          let cookiesEntry = { key: key || '', value: value || '' };
          cookiesArray.push(cookiesEntry);
        });
        setResponseCookies(cookiesArray);
      }
      if (!!response.headers?.length) {
        !!checkContentType && (bodyType = checkContentType.value.split(';')[0].split('/')[1]);
        bodyType === 'plain' && (bodyType = 'plaintext');
        let fileType;
        response.headers?.map((item) => {
          const expectedMime = [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'image',
            'jpeg',
            'png',
            'pdf'
          ];
          if (item.key.toLowerCase() === 'content-type' && expectedMime.includes(item.value)) {
            fileType = item.value;
            return setResponseFile((prev) => {
              return {
                ...prev,
                isFile: true,
                fileType,
                fileBase64: response.body.replace(/\s/g, '')
              };
            });
          }
        });
      }
      setRunsResult(response);
      setResponseHeaders(response.headers);
      if (bodyType.includes('json')) {
        setJSONResponseFields({
          keys: getJSONResponseKeys(cleanJson(response.body)),
          fields: getJSONResponseFields(cleanJson(response.body))
        });
        setApiResponse(cleanJson(response.body));
        handleResponseEditorStateChange({
          value: JSON.stringify(cleanJson(response.body), null, 2),
          responseEditorLanguage: bodyType
        });
      } else {
        setApiResponse(response.body);
        handleResponseEditorStateChange({
          value: response.body,
          responseEditorLanguage: bodyType
        });
      }
      // formData file_url swap
      if (requestData.mode === 'formData' && requestData?.body?.length > 0) {
        let _obj = [...formData];
        const requestBodyWithFile = requestData.body.filter((requests) => {
          return requests.type === 'file';
        });
        const newFormData = _obj.map((entry) => {
          requestBodyWithFile.map((request) => {
            if (entry.id === request.id) {
              entry.value = request.value;
            }
          });
          return entry;
        });

        setFormData(newFormData);
      }
      setResponseHeaders(response.headers);
      if (!!response?.size) {
        const _size = prettyBytes(+response?.size);
        setSize(_size);
      } else {
        setSize(0);
      }
      setSaveHighLightedTextState((prev) => {
        return { ...prev, highLightedValue: '', showModal: false };
      });
      setStatus(response.status_code);
      setAssertionsResults(response.assertions);
      scrollToResponse();
      queryClient.invalidateQueries({ queryKey: ['global-variables'] });
      queryClient.invalidateQueries({ queryKey: ['global-variables-suggestion-list'] });
    },
    onError: (error) => {
      // console.log('got here error');
      setShowResponseProps(true);
      setResponseTab(0);
      setStatus(error?.data?.status || 0);
      setTimeTaken(null);
      setSize(null);
      setResponseHeaders([]);
      setAssertionsResults({});
      setShowRunProperty(false);
      const err = JSON.stringify(error, null, 2);
      setApiResponse(cleanJson(err));
      handleResponseEditorStateChange({
        value: JSON.stringify(cleanJson(err), null, 2),
        responseEditorLanguage: 'json'
      });
    }
  });
  // this returns the data object to be included in the request body, it is of syntax ({url: 'your-url', request_method: "get or put or throw away"}, e.t.c)
  // LOCAL RUN LOGIC
  //
  const configureBodyForLocalRun = async () => {
    let requestBodyForConsole;
    let requestBody = null;
    if (requestBodyType === 'formData') {
      requestBodyForConsole = [];
      const formDataBody = new FormData();
      let pop = await Promise.all(
        formData.map(async (item) => {
          if (!!item.key && !!item.value) {
            if (item.type === 'text') {
              let parsedKey = parseStringForGlobalAndDynamicValues(item.key, variablesServerState);
              let parsedValue = parseStringForGlobalAndDynamicValues(
                item.value,
                variablesServerState
              );
              formDataBody.append(parsedKey, parsedValue);
              requestBodyForConsole.push({ key: parsedKey, value: parsedValue });
              requestBody = formDataBody;
              return;
            }
            if (item.type === 'file') {
              let parsedKey = parseStringForGlobalAndDynamicValues(item.key, variablesServerState);
              if (item.value.startsWith('data:')) {
                formDataBody.append(parsedKey, item.rawFile, item?.filename);
                requestBodyForConsole.push({ key: parsedKey, value: item.filename });
                requestBody = formDataBody;
              } else if (item.value.startsWith('https:')) {
                let url = item.value.replace(/\n/g, '');
                let result = await fetch(url);
                result = await result.blob();
                requestBodyForConsole.push({ key: parsedKey, value: item.value });
                formDataBody.append(parsedKey, result);
                requestBody = formDataBody;
              }
            }
          }
          return item;
        })
      );
      // requestBody = formDataBody;
    } else if (requestBodyType === 'urlencoded') {
      let urlEncodedBody = [];
      formUrlEncoded.map((item) => {
        if (!!item.key && !!item.value) {
          let parsedKey = parseStringForGlobalAndDynamicValues(item.key, variablesServerState);
          let parsedValue = parseStringForGlobalAndDynamicValues(item.value, variablesServerState);
          urlEncodedBody.push({ key: parsedKey, value: parsedValue });
        }
      });
      urlEncodedBody = urlEncodedBody
        .map((item) => {
          const encodedKey = encodeURIComponent(item.key);
          const encodedValue = encodeURIComponent(item.value);
          return `${encodedKey}=${encodedValue}`;
        })
        .join('&');
      requestBodyForConsole = urlEncodedBody;
      requestBody = urlEncodedBody;
      // console.log(requestBody);
    } else if (requestBodyType === 'raw' && language === 'graphql' && !!rawContent) {
      let parsedRawContent = parseStringForGlobalAndDynamicValues(rawContent, variablesServerState);
      requestBody = JSON.stringify({
        query: parsedRawContent,
        variables: cleanJson(graphqlVariables)
      });
      requestBodyForConsole = requestBody;
    } else if (requestBodyType === 'none') {
      requestBody = null;
      requestBodyForConsole = requestBody;
    } else {
      let parsedRawContent = rawContent;
      // json,jsvavscript,html e.t.c
      if (language === 'json') {
        parsedRawContent = rawContent.replace(/\/\/.*$/gm, '').replace(/\/\*[\s\S]*?\*\//g, '');
        parsedRawContent = replaceDynamicBooleanValueInJSONBody(parsedRawContent);
      }
      parsedRawContent = parseStringForGlobalAndDynamicValues(
        parsedRawContent,
        variablesServerState
      );
      requestBody = parsedRawContent;
      requestBodyForConsole = requestBody;
    }
    return { requestBody, requestBodyForConsole };
  };
  const configureHeadersForLocalRun = () => {
    let _headers = queryHeaders?.filter((header) => header.key && header.value);
    //swap headers values for dynamic vlaues, then global variables if present
    _headers = parseStringForGlobalAndDynamicValues(JSON.stringify(_headers), variablesServerState);
    _headers = JSON.parse(_headers);
    let headersForFetch = {};
    // const requestDataHeaders = _headers;
    _headers = _headers.forEach((entry) => {
      headersForFetch[entry.key] = entry.value;
    });
    //set content-Type if present
    const ContentTypeIsPresent = Object.keys(headersForFetch).find((key) => {
      return key.toLowerCase() === 'content-type';
    });

    if (!ContentTypeIsPresent) {
      if (requestBodyType === 'formData') {
        headersForFetch = {};
      } else if (requestBodyType === 'urlencoded') {
        headersForFetch['Content-Type'] = 'application/x-www-form-urlencoded';
      } else if (requestBodyType === 'raw') {
        if (language === 'json' || language === 'graphql') {
          headersForFetch['Content-Type'] = 'application/json';
        } else if (language === 'xml' || language === 'javascript') {
          headersForFetch['Content-Type'] = `application/${language}`;
        } else if (language === 'plaintext') {
          headersForFetch['Content-Type'] = `text/plain`;
        }
      } else {
        headersForFetch['Content-Type'] = `text/plain`;
      }
    }
    return { headersForFetch };
  };
  const configureTestDataForLocal = async () => {
    let request_data = {};
    let _url = swapPathVariablesInURL(url, pathVariables); // firstly replace pathVariables

    _url = parseStringForGlobalAndDynamicValues(_url, variablesServerState); //then global and dynamic variables
    // filter entries without values
    const filterEmptyParams = queryParams.filter((entry) => {
      return entry.src === 'authorization' || (!!entry.value && !!entry.key);
    });
    const searchParams = new URLSearchParams();
    // parse params for global variables
    filterEmptyParams.forEach((entry) => {
      searchParams.append(entry.key, entry.value);
      // return `${encodeURIComponent(entry.key)}=${encodeURIComponent(entry.value)}`;
    });
    let newUrl = new URL(_url);
    newUrl.search = searchParams;
    let { requestBody, requestBodyForConsole } = await configureBodyForLocalRun();
    let { headersForFetch } = configureHeadersForLocalRun();
    newUrl = newUrl.toString();
    request_data.url = newUrl;
    request_data.mode = requestBodyType;
    request_data.headers = headersForFetch;
    request_data.body = requestBodyForConsole;
    request_data.request_method = httpMethod.toUpperCase();
    request_data.language = language;
    return { newUrl, requestBody, headersForFetch, request_data };
  };

  const desktopAgentMutationFn = async () => {
    setIsRunningByAgent(true);
    const { newUrl, headersForFetch } = await configureTestDataForLocal();
    let requestBody = configureRequestBodyForDeskTopAgent();
    let data = {
      request_method: httpMethod.toUpperCase() || 'GET',
      mode: httpMethod.toUpperCase() === 'GET' ? 'none' : requestBodyType,
      language,
      url: newUrl,
      headers: headersForFetch,
      requestBody: httpMethod.toUpperCase() === 'GET' ? null : requestBody
    };
    // console.log(data);
    const startTime = performance.now();
    const response = await fetch(`http://localhost:${agentPort}/api/run-test`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const endTime = performance.now();
    const time_taken = endTime - startTime;
    let result = await response.json();
    const allAssertionResult = runAssertions(
      updateTestAssertion(),
      result.status_code,
      result.body,
      result.headers,
      result.contentType,
      result.size,
      time_taken,
      handleUpdateVariablesIfAsserted
    );
    let addToResult = { ...result, time_taken, assertions: allAssertionResult };
    if (!response.ok) {
      let error = new Error();
      error.data = result;
      throw error;
    }
    return addToResult;
  };
  const handleRunTestByDesktopAgent = async () => {
    try {
      await runByScandiumAgent();
    } catch (error) {
      throw error;
    }
  };
  const configureRequestBodyForDeskTopAgent = () => {
    let requestBody = null;
    if (requestBodyType === 'formData') {
      requestBody = formData.filter((item) => {
        return !!item.key && !!item.value;
      });
      requestBody = parseStringForGlobalAndDynamicValues(
        JSON.stringify(requestBody),
        variablesServerState
      );
      requestBody = JSON.parse(requestBody);
      requestBody = requestBody;
    } else if (requestBodyType === 'urlencoded') {
      let urlEncodedBody = [];
      formUrlEncoded.map((item) => {
        if (!!item.key && !!item.value) {
          urlEncodedBody.push({ key: item.key, value: item.value });
        } // why not filter?
      });
      urlEncodedBody = parseStringForGlobalAndDynamicValues(
        JSON.stringify(urlEncodedBody),
        variablesServerState
      );
      urlEncodedBody = JSON.parse(urlEncodedBody);
      urlEncodedBody = urlEncodedBody
        .map((item) => {
          const encodedKey = encodeURIComponent(item.key);
          const encodedValue = encodeURIComponent(item.value);
          return `${encodedKey}=${encodedValue}`;
        })
        .join('&');
      requestBody = urlEncodedBody;
    } else if (requestBodyType === 'raw' && language === 'graphql' && !!rawContent) {
      let parsedRawContent = parseStringForGlobalAndDynamicValues(rawContent, variablesServerState);
      requestBody = {
        query: parsedRawContent,
        variables: cleanJson(graphqlVariables)
      };
    } else if (requestBodyType === 'none') {
      requestBody = null;
    } else {
      // json,jsvavscript,html e.t.c
      let parsedRawContent = rawContent;
      if (language === 'json') {
        parsedRawContent = rawContent.replace(/\/\/.*$/gm, '').replace(/\/\*[\s\S]*?\*\//g, '');
        // console.log(parsedRawContent);
        parsedRawContent = replaceDynamicBooleanValueInJSONBody(parsedRawContent);
      }

      parsedRawContent = parseStringForGlobalAndDynamicValues(rawContent, variablesServerState);
      requestBody = parsedRawContent;
    }
    return requestBody;
  };
  const { mutateAsync: runByScandiumAgent, isLoading } = useMutation({
    mutationFn: desktopAgentMutationFn,
    retry: 0,
    onSuccess: (data) => {
      setShowResponseProps(true);
      setShowRunProperty(true);
      setIsRunningByAgent(false);
      scrollToResponse();
      const {
        status_code,
        body,
        headers: responseHeaders,
        time_taken,
        isBlob,
        contentType,
        size,
        assertions: localRunAssertionsResult,
        request_data
      } = data;
      let bodyType;
      if (!!request_data) {
        setConsoleData((prev) => [...prev, request_data]);
      }
      if (!!contentType) {
        bodyType = contentType.split(';')[0].split('/')[1];
        bodyType === 'plain' && (bodyType = 'plaintext');
      }
      let responseBody = body;
      if (!responseBody) {
        responseBody = 'No response body from server';
      }
      const cookies = responseHeaders.find((header) => header.key.toLowerCase() === 'set-cookie');
      if (!!cookies) {
        let splitValues = cookies.value.split(';');
        let cookiesArray = [];
        splitValues.forEach((cookieField) => {
          const [key, value] = cookieField.split('=');
          let cookiesEntry = { key: key || '', value: value || '' };
          cookiesArray.push(cookiesEntry);
        });
        data.cookies = cookiesArray;
        setResponseCookies(cookiesArray);
      }
      let runResponse = data;
      if (!isBlob) {
        if (contentType.includes('json')) {
          runResponse.body = JSON.stringify(runResponse.body);
        }
        setRunsResult(runResponse);
        setResponseFile((prev) => {
          return {
            ...prev,
            isFile: false,
            fileBase64: '',
            fileType: ''
          };
        });
        if (contentType.includes('json')) {
          setJSONResponseFields({
            keys: getJSONResponseKeys(responseBody),
            fields: getJSONResponseFields(responseBody)
          });
          setApiResponse(responseBody);
          handleResponseEditorStateChange({
            value: JSON.stringify(responseBody, null, 2),
            responseEditorLanguage: 'json'
          });
        } else {
          setApiResponse(responseBody);
          handleResponseEditorStateChange({
            value: responseBody,
            responseEditorLanguage: bodyType
          });
          // cr8 a function to better extract contentype
        }
      } else {
        setApiResponse(responseBody);
        setRunsResult(runResponse);
        setResponseFile((prev) => {
          return {
            ...prev,
            isFile: true,
            fileBase64: responseBody,
            fileType: contentType
            // blobFromLocalRun: responseBody
          };
        });
      }
      setResponseHeaders(responseHeaders);

      setSize(prettyBytes(size ? +size : JSON.stringify(body)?.length));
      setSaveHighLightedTextState((prev) => {
        return { ...prev, highLightedValue: '', showModal: false };
      });
      setStatus(status_code);
      setTimeTaken(time_taken);
      setAssertionsResults(localRunAssertionsResult);
      queryClient.invalidateQueries({ queryKey: ['global-variables'] });
    },
    onError: (error) => {
      setShowResponseProps(true);
      setResponseTab(0);
      setStatus(error?.data?.status || 0);
      setTimeTaken(null);
      setSize(null);
      setResponseHeaders([]);
      setAssertionsResults({});
      setIsRunningByAgent(false);
      if (!!error?.data?.request_data) {
        setConsoleData((prev) => [...prev, error?.data?.request_data]);
      }
      if (!!error?.data?.status) {
        setApiResponse(error.data);
        handleResponseEditorStateChange({
          value: JSON.stringify(error.data, null, 2),
          responseEditorLanguage: 'json'
        });
      }
      if (error.message === 'Failed to fetch') {
        toast.error('Failed to run test. Please try again');
      }
      return;
    }
  });
  const runTestLocally = async () => {
    let requestData;
    try {
      const { newUrl, requestBody, headersForFetch, request_data } =
        await configureTestDataForLocal();
      const startTime = performance.now();
      if (headersForFetch['Content-Type'] === 'multipart/form-data') {
        delete headersForFetch['Content-Type'];
      }
      requestData = request_data;
      const response = await fetch(newUrl, {
        method: httpMethod.toUpperCase() || 'GET',
        headers: { ...headersForFetch },
        body: httpMethod.toUpperCase() === 'GET' ? null : requestBody
        // body: requestBody
      });
      const endTime = performance.now();
      const time_taken = endTime - startTime;
      if (response) {
        const { body, isBlob, contentType } = await functionToCreateResponseBody(response);
        const headers = functionToExtractResponseHeaders(response.headers);
        const size = response.headers.get('content-length');
        const allAssertionResult = runAssertions(
          updateTestAssertion(),
          response.status,
          body,
          headers,
          contentType,
          time_taken,
          handleUpdateVariablesIfAsserted
        );
        const data = {
          status_code: response.status,
          body,
          headers,
          size,
          time_taken,
          isBlob,
          contentType,
          assertions: allAssertionResult,
          request_data
        };
        return {
          status: response.status,
          message: 'Ran API Test Successfully',
          data
        };
      }
    } catch (error) {
      let runData = requestData;
      runData.isError = true;
      error.requestData = runData;
      if (error?.name === 'TypeError') {
        const newError = new Error(error?.message || 'Failed to fetch, please try again');
        newError.requestData = runData;
        throw newError;
      }
      throw error;
    }
  };
  const { isFetching: isRunningTestLocally, refetch: reRunTestLocally } = useQuery({
    queryKey: ['run_test_locally'],
    retry: 0,
    queryFn: runTestLocally,
    enabled: !!localRun,
    onSuccess: async (data) => {
      setResponseTab(0);
      setShowResponseProps(true);
      setShowRunProperty(true);
      scrollToResponse();
      const {
        status_code,
        body,
        size,
        headers: responseHeaders,
        time_taken,
        isBlob,
        contentType,
        assertions: localRunAssertionsResult,
        request_data
      } = data.data;
      let responseBody = body;
      if (!responseBody) {
        responseBody = 'No response body from server';
      }
      if (!!request_data) {
        setConsoleData((prev) => [...prev, request_data]);
      }
      const runResponse = data.data;
      if (!isBlob) {
        if (contentType.includes('json')) {
          runResponse.body = JSON.stringify(runResponse.body);
        }
        setRunsResult(runResponse);
        if (contentType.includes('json')) {
          setJSONResponseFields({
            keys: getJSONResponseKeys(responseBody),
            fields: getJSONResponseFields(responseBody)
          });

          setApiResponse(responseBody);
          handleResponseEditorStateChange({
            value: JSON.stringify(responseBody, null, 2),
            responseEditorLanguage: 'json'
          });
        } else {
          setApiResponse(responseBody);
          handleResponseEditorStateChange({
            value: responseBody,
            responseEditorLanguage: contentType.split(';')[0].split('/')[1]
          });
        }
      } else {
        // extract the base64 string for saving purpose
        let base64String = await fileParser2(responseBody);
        base64String = base64String.split(',')[1];
        // console.log(base64String);
        let responseForSavingPurpose = { ...runResponse };
        responseForSavingPurpose.body = base64String;
        setRunsResult(responseForSavingPurpose);
        setResponseFile((prev) => {
          return {
            ...prev,
            isFile: true,
            fileBase64: base64String,
            fileType: contentType
            // blobFromLocalRun: responseBody
          };
        });
      }
      setResponseHeaders(responseHeaders);
      setSize(prettyBytes(+size));
      const cookies = responseHeaders.find((header) => header.key.toLowerCase() === 'set-cookie');
      if (!!cookies) {
        let splitValues = cookies.value.split(';');
        let cookiesArray = [];
        splitValues.forEach((cookieField) => {
          const [key, value] = cookieField.split('=');
          let cookiesEntry = { key: key || '', value: value || '' };
          cookiesArray.push(cookiesEntry);
        });
        setResponseCookies(cookiesArray);
      }
      setSaveHighLightedTextState((prev) => {
        return { ...prev, highLightedValue: '', showModal: false };
      });
      setStatus(status_code);
      setTimeTaken(time_taken);
      setAssertionsResults(localRunAssertionsResult);
      queryClient.invalidateQueries({ queryKey: ['global-variables'] });
      setLocalRun(false);
    },
    onError: (error) => {
      if (!!error.requestData) {
        setConsoleData((prev) => [...prev, error.requestData]);
      }
      setStatus(0);
      setTimeTaken(null);
      setSize(null);
      setResponseHeaders([]);
      setAssertionsResults({});
      setApiResponse(error?.message);
      setShowResponseProps(true);
      setResponseTab(0);
      setLocalRun(false);
      setResponseFile((prev) => {
        return {
          ...prev,
          isFile: false,
          fileBase64: '',
          fileType: ''
        };
      });
      handleResponseEditorStateChange({
        value: error.message,
        responseEditorLanguage: 'plaintext'
      });
      toast.error(error.message, {
        autoClose: 1500
      });
      // if (error.name === 'fetch-error') {
      //   let errorData = error?.data;
      //   setRunsResult(errorData);
      //   setStatus(errorData?.status_code);
      //   setResponseHeaders(errorData?.headers);
      //   setAssertionsResults(errorData?.assertions);
      //   setApiResponse(errorData?.body);
      //   handleResponseEditorStateChange({
      //     value: JSON.stringify(errorData?.body, null, 2)
      //   });
      //   setSaveHighLightedTextState((prev) => {
      //     return { ...prev, highLightedValue: '', showModal: false };
      //   });
      //   const _size = prettyBytes(
      //     JSON.stringify(errorData?.headers).length + JSON.stringify(errorData?.body?.length)
      //   );
      //   setSize(_size);
      //   setTimeTaken(errorData?.time_taken);
      // }
    }
  });
  const { mutateAsync: updateVariablesIfAsserted } = useScandiumMutation(
    `/projects/${activeProject?.id}/variables`,
    {
      enabled: !!globalVariableState.globalVariables.length,
      onError: (error) => {},
      onSuccess: (data) => {
        toast.success(data.message, {
          autoClose: 800
        });
        queryClient.invalidateQueries({ queryKey: ['global-variables'] });
      }
    }
  );
  const handleUpdateVariablesIfAsserted = async (newEntry) => {
    await updateVariablesIfAsserted({
      variables: [...variablesServerState, newEntry]
    });
  };
  const handleRunTestLocally = () => {
    if (!url) {
      toast.error('url field cannot be empty');
      return;
    }
    if (!!localRun) {
      reRunTestLocally();
      return;
    }
    setLocalRun(true);
  };
  //------------------------------------
  const testData = () => {
    const _headers = queryHeaders?.filter((header) => header.key && header.value);
    // const _authParams
    const _params = queryParams?.filter((param) => {
      return (param.key && param.value) || param.src === 'authorization';
    });

    const _authorization = {
      value: authorizationHeader
    };

    const _cookies = requestCookies?.filter((param) => param.key && param.value);

    const _formData = formData?.filter((entry) => entry.key && entry.value);
    const _formUrlEncoded = formUrlEncoded?.filter((entry) => entry.key && entry.value);

    const req_body = () => {
      let requestBody = {};

      if (requestBodyType === 'urlencoded' && _formUrlEncoded.length > 0) {
        requestBody = _formUrlEncoded;
      } else if (requestBodyType === 'formData' && _formData.length > 0) {
        requestBody = _formData;
      } else if (requestBodyType === 'raw') {
        if (language === 'json') {
          requestBody = cleanJson(rawContent);
        } else if (language === 'graphql') {
          requestBody.query = rawContent;
          requestBody.variables = cleanJson(graphqlVariables) || {};
        } else {
          requestBody = rawContent;
        }
      } else if (requestBodyType === 'none') {
        requestBody = '';
      }
      return requestBody;
    };
    const _data = {
      url: url,
      authorization: JSON.stringify(_authorization),
      request_method: httpMethod.toLowerCase(),
      headers: _headers || [],
      params: _params || [],
      pathVariables,
      cookies: _cookies || [],
      mode: requestBodyType,
      body: req_body()
    };
    if (requestBodyType === 'raw') {
      _data.mode = language;
    }
    return _data;
  };

  const updateTestAssertion = () => {
    const newAssertion = [];
    const filterEmptydropdowns = dropdowns.filter((assertion) => {
      let hasValidValues;
      if (assertion.selectedOption === 'response header') {
        hasValidValues = !!assertion.value.key && !!assertion.value.value;
      } else if (assertion.selectedOption === 'create global variable') {
        hasValidValues =
          !!assertion.value?.variableName?.trim() && !!assertion.value?.value?.trim();
        if (assertion?.value?.cascade !== true) {
          hasValidValues = !!assertion.value.value;
        }
      } else {
        const rangeValues = assertion.range.filter(Boolean).length > 1;
        hasValidValues = !!assertion?.value || !!assertion?.oneOf.length || !!rangeValues;
      }
      return !!hasValidValues;
    });
    filterEmptydropdowns.map((assertion) => {
      switch (assertion.selectedOption) {
        case 'status code':
          const obj = {};
          obj.name = 'status_code';
          obj.operator = assertion.selectedOperator;
          if (assertion.selectedOperator !== 'one_of' && assertion.selectedOperator !== 'range') {
            obj.value = assertion.value;
          } else if (assertion.selectedOperator === 'one_of') {
            obj.value = assertion.oneOf;
          } else if (assertion.selectedOperator === 'range') {
            obj.value = assertion.range;
          }
          newAssertion.push(obj);
          break;
        case 'response body type':
          const bodyTpyeObj = {};
          bodyTpyeObj.name = 'response_body_type';
          bodyTpyeObj.operator = assertion.selectedOperator;
          bodyTpyeObj.value = assertion.value;
          newAssertion.push(bodyTpyeObj);
          break;
        case 'request duration':
          const requestDurationObj = {};
          requestDurationObj.name = 'request_duration';
          requestDurationObj.operator = assertion.selectedOperator;
          requestDurationObj.value = assertion.value;
          if (requestDurationObj.operator === 'range') {
            requestDurationObj.value = assertion.range;
          }
          newAssertion.push(requestDurationObj);
          break;
        case 'response header':
          const responseHeadersObj = assertion.value;
          let headerEntry;
          headerEntry = newAssertion.find((item) => {
            return item.name === 'response_headers';
          });
          if (headerEntry) {
            headerEntry.value.push(responseHeadersObj);
          } else {
            newAssertion.push({ name: 'response_headers', value: [] });
            headerEntry = newAssertion.find((item) => {
              return item.name === 'response_headers';
            });
            headerEntry.value.push(responseHeadersObj);
          }
          break;
        case 'response body content':
          const responseBodyContent = {};
          responseBodyContent.value = assertion.value;
          responseBodyContent.operator = assertion.selectedOperator;

          let bodyContentEntry;
          bodyContentEntry = newAssertion.find((item) => {
            return item.name === 'response_body_content';
          });
          if (bodyContentEntry) {
            bodyContentEntry.value.push(responseBodyContent);
          } else {
            newAssertion.push({ name: 'response_body_content', value: [] });
            bodyContentEntry = newAssertion.find((item) => {
              return item.name === 'response_body_content';
            });
            bodyContentEntry.value.push(responseBodyContent);
          }
          break;
        case 'create global variable':
          // const objd = {...assertion}
          let globalVariableObj = {};
          globalVariableObj.variableName = assertion.value.variableName;
          globalVariableObj.value = assertion.value.value;
          globalVariableObj.cascade = assertion.value.cascade;

          if (globalVariableObj.value.startsWith('.')) {
            const valueWithoutDot = assertion.value.value.substring(1);
            globalVariableObj.value = valueWithoutDot;
          }
          let globalVariableEntry;
          globalVariableEntry = newAssertion.find((item) => {
            return item.name === 'create_global_variable';
          });
          if (globalVariableEntry) {
            globalVariableEntry.value.push(globalVariableObj);
          } else {
            newAssertion.push({ name: 'create_global_variable', value: [] });
            globalVariableEntry = newAssertion.find((item) => {
              return item.name === 'create_global_variable';
            });
            globalVariableEntry.value.push(globalVariableObj);
          }
          break;
      }
    });
    return newAssertion;
  };
  // this is responsible for url tests
  const handleTestRuns = async () => {
    setResponseFile((prev) => {
      return { ...prev, isFile: false, blobFromLocalRun: '' };
    });
    if (!url) {
      toast.error('Please enter a request url');
      return;
    }
    const _data = {
      data: testData(),
      assertions: updateTestAssertion()
    };
    // console.log(_data.data);
    try {
      const data = await _runTest({
        projectId,
        data: _data,
        testId: testId ? testId : null
      });
      setRunsResult(data?.data);
      setTimeTaken(data?.data?.time_taken);
    } catch (e) {
      toast.error('Failed to run test. Please try again');
    }
  };

  const handleSaveTest = async (_title = '') => {
    let savedTestWithoutResponseBody = null;
    if (!!savedTest?.response?.body) {
      let { body, ...ResponseBody } = savedTest.response;
      savedTestWithoutResponseBody = ResponseBody;
    } else if (!!runsResult) {
      let { body, ...responseBody } = runsResult;
      savedTestWithoutResponseBody = responseBody;
    }
    try {
      if (!url) {
        toast.error('Please enter a request url');
        return;
      }

      if (!title) {
        toast.error('Please enter a test name');
        return;
      }

      const _testData = {
        name: title,
        data: testData(),
        response: savedTestWithoutResponseBody,
        assertions: updateTestAssertion()
      };
      let {
        data: { mode, body }
      } = _testData;
      // check for invalid json
      if (mode === 'json' && !!rawContent.length && !body) {
        toast.error('request body is not valid json');
        return;
      }
      if (folderId) _testData['folder_id'] = +folderId;
      const data = await saveTest(_testData);
      toast.success(
        savedTest?.id ? 'Test configuration updated' : 'Test configuration saved successfully!',
        {
          autoClose: 1000
        }
      );
      setSavedTest(data?.data);
      return true;
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // If there is no testId in the url
    if (!testId && !!savedTest) {
      navigate(pathname.replace('new', `${savedTest.id}/edit`), { replace: true });
    }
  }, [testId, savedTest]);

  const extractQueryParams = (_url) => {
    const params = {};
    const searchParams = new URLSearchParams(new URL(_url).search);
    for (const [key, value] of searchParams) {
      params[key] = value;
    }

    return params;
  };
  const numberOfHeaders = () => {
    return queryHeaders.filter((header) => !!header.key.trim()).length;
  };
  // Making query parameters in URL in sync with params in table
  useEffect(() => {
    if (!isValidUrl(debouncedUrlString)) {
      return;
    }

    let searchParamsPairs = new URL(url).search.substring(1).split('&');
    let newParams = [];
    searchParamsPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      newParams.push({
        key: !!key ? key : '',
        value: !!value ? value : '',
        description: null
      });
    });
    if (!newParams.length) {
      newParams.push({ key: '', value: '', description: null });
      setQueryParams((prev) => {
        const authParams = prev.filter((entry) => {
          return entry.src === 'authorization';
        });
        if (authParams.length > 0) {
          return [...authParams, ...newParams];
        }
        return [...newParams];
      });
    }
    if (!!newParams.length) {
      const lastRow = newParams[newParams.length - 1];
      if (lastRow.key.trim() !== '' && lastRow.value.trim() !== '') {
        newParams.push({ key: '', value: '', description: null });
      }
      newParams = newParams.map((entry, index) => {
        entry.description = queryParams[index]?.description;
        return entry;
      });
      setQueryParams((prev) => {
        const authParams = prev.filter((entry) => {
          return entry.src === 'authorization';
        });
        if (authParams.length > 0) {
          return [...authParams, ...newParams];
        }
        return [...newParams];
      });
    }
    setParamsUpdated(true);
  }, [debouncedUrlString]);
  // Copy response to clipboard
  const copyResponseToClipBoard = () => {
    const jsonData = JSON.stringify(apiResponse, null, 2);
    navigator.clipboard.writeText(jsonData);
    toast.success('copied to clipboard');
  };
  // Check for valid query params
  const hasValidQueryParams = queryParams.some((item) => {
    return item.key?.trim() !== '' && item.value?.trim() !== '';
  });
  const hasValidQueryHeaders = queryHeaders.some((item) => {
    return item.key?.trim() !== '' && item.value?.trim() !== '';
  });
  // Check for valid cookies
  const hasValidCookies = requestCookies.some((item) => {
    return item.key?.trim() !== '' && item.value?.trim() !== '';
  });

  const { isFeatureEnabled: showAPIMenu } = useFeatureEnabled('api-testing');
  useEffect(() => {
    const value = localStorage.getItem('scandium-mode');
    if (!!value) {
      setRunAgent(value);
    } else {
      localStorage.setItem('scandium-mode', 'Cloud-Agent');
      // setRunAgent('Cloud-Agent')
    }
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!!isTestChanges) {
        const message = 'You have unsaved changes, Are you sure you want to leave';
        event.returnValue = 'You have unsaved changes, Are you sure you want to leave';
        return message;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isTestChanges]);

  return (
    <Box
      sx={{
        marginTop: { xs: '3.5rem', sm: '9rem' },
        ml: { xs: '1.5rem', sm: '3rem', md: '4rem' },
        mr: { xs: '1.5rem', sm: '2rem', md: '2rem' },
        color: 'inherit',
        maxHeight: 'max-content'
      }}>
      {!isFeatureFlagsLoading && showAPIMenu && (
        <>
          <Box>
            <Flex columnGap={0} alignItems={'flex-end'}>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  textDecoration: 'none',
                  textAlign: 'left',
                  fontSize: '1.1rem',
                  cursor: 'pointer'
                }}>
                {activeFolder?.name || `Test case`} /
              </Typography>
              <EditableTitle
                value={title}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditableIndicator showHelper />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ px: 0, width: title ? title.length * 12 : 200, minWidth: 200 }}
              />
              <ChooseRunAgent displayRunAgentModal={requestTestRunAgentModal} />
            </Flex>
          </Box>
          <Flex
            sx={{
              flexDirection: { xs: 'column', md: 'column', lg: 'row' },
              alignItems: { xs: 'flex-start', lg: 'center' }
              // mt: 1,
              // justifyContent: 'space-between'
            }}>
            <Flex columnGap={0} alignItems={'stretch'} minWidth={'600px'}>
              <HttpMethodsMenu httpMethod={httpMethod} setHttpMethod={setHttpMethod} />
              <TextField
                required
                value={url}
                disabled={!!readyOnly}
                // onClick={(event) => {
                //   setUrl(event.target.value);
                // }}
                onChange={(event) => {
                  setUrl(event.target.value);
                  handleVariableFloatingWindow(event, () => setUrl(event.target.value));
                  if (event.target.value.includes('localhost')) {
                    setShowLocalModeNotice(true);
                  } else {
                    setShowLocalModeNotice(false);
                  }
                }}
                size={'small'}
                autoFocus={pathname.includes('new')}
                fullWidth
                placeholder={'Enter URL or paste text'}
                InputProps={{
                  sx: {
                    paddingLeft: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                  },
                  endAdornment: !isPageLoading ? undefined : (
                    <InputAdornment position="end">
                      <CircularProgress size={12} color={'secondary'} />
                    </InputAdornment>
                  )
                }}
              />
            </Flex>
            <Flex
              ml={{ lg: 'auto', xs: '', md: '' }}
              my={1}
              sx={{
                gap: 2
              }}>
              {showLocalModeNotice && runAgent !== 'Browser-Agent' && (
                <Fade
                  timeout={{
                    enter: 800,
                    exit: 300
                  }}
                  in={showLocalModeNotice && runAgent !== 'Browser-Agent'}>
                  <Chip
                    sx={{
                      borderRadius: 2
                    }}
                    variant="filled"
                    color="primary"
                    onDelete={() => {
                      setShowLocalModeNotice(false);
                    }}
                    label={
                      'seems your server is running on your computer, consider switching to Browser Mode'
                    }
                  />
                </Fade>
              )}
              <SelectTestRunAgent
                open={openTestRunAgentModal}
                onClose={onCloseTestRunAgentModal}
                // checkIfAgentIsOn={checkIfAgentIsOn}
              />
              <ContainedButton
                isLoading={isRunningTestLocally}
                disabled={isRunningTestLocally}
                loadingProps={{ size: 16 }}
                onClick={() => {
                  runAgent === 'Cloud-Agent' && handleTestRuns();
                  runAgent === 'Browser-Agent' && handleRunTestLocally();
                  runAgent === 'Desktop-Agent' && handleRunTestByDesktopAgent();
                }}
                startIcon={
                  <SendSvg
                    fill={theme.palette.svg.main}
                    sx={{
                      transform: runAgent === 'Browser-Agent' ? 'rotate(180deg)' : ''
                    }}
                  />
                }>
                Send
              </ContainedButton>
              {/* )} */}
              <Tooltip title={'Save request'}>
                <OutlinedButton
                  isLoading={isSavingTest}
                  disabled={
                    isSavingTest || !!exampleId || !!readyOnly || projectId !== activeProject?.id
                  }
                  loadingProps={{ size: 16 }}
                  onClick={handleSaveTest}
                  endIcon={
                    !!isTestChanges && (
                      <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '5px' }} />
                    )
                  }
                  startIcon={<SaveSvg fill={theme.palette.svg.main} />}>
                  Save
                </OutlinedButton>
              </Tooltip>
              {/* <Tooltip title={'Save request'}>
                <OutlinedButton
                  // isLoading={isSavingTest}
                  // disabled={isSavingTest || !!exampleId || !!readyOnly}
                  loadingProps={{ size: 16 }}
                  onClick={async () => {
                    runByScandiumAgent();
                  }}
                  startIcon={<SaveSvg />}>
                  agent
                </OutlinedButton>
              </Tooltip> */}
            </Flex>
          </Flex>
          <Divider sx={{ mt: 1 }} />

          <Flex // main section
            columnGap={0}
            alignItems="flex-start"
            paddingRight={0}>
            <Flex
              sx={{
                flexDirection: 'column',
                flex: '30%',
                minWidth: '200px',
                maxWidth: 'inherit',
                alignItems: 'normal',
                pt: 1.5,
                bgcolor: theme.palette.background.s1,
                borderLeft: `0.5px solid #ccc`,
                borderBottom: '0.5px solid #ccc',
                overflowX: 'scroll'
              }}>
              <Flex justifyContent={'flex-end'} columnGap={1} sx={{ mr: 1 }}>
                <Tooltip title={'Create or manage your variables'}>
                  <Button
                    disabled={!!massSelection.active}
                    disableRipple
                    onClick={() => {
                      setGlobalVariableState((prev) => {
                        return {
                          ...prev,
                          openModal: true,
                          useCase: 'add_variables'
                        };
                      });
                    }}
                    sx={{ py: 0, px: 0, '&: hover': { background: 'transparent' } }}>
                    Variable
                  </Button>
                </Tooltip>
                <Tooltip title={'Create a new test'}>
                  <Button
                    disabled={!!massSelection.active}
                    disableRipple={true}
                    onClick={() => {
                      if (isTestChanges) {
                        requestSaveChangesDialogNavToNew();
                        return;
                      }
                      resetInitialState();
                      navigate(`/projects/${activeProject?.id}/api-suites/new`);
                    }}
                    sx={{ py: 0, px: 0, '&: hover': { background: 'transparent' } }}>
                    New
                  </Button>
                </Tooltip>
                <Tooltip title={'Import a POSTMAN Collection'}>
                  <Button
                    disabled={!!massSelection.active}
                    onClick={() => requestImportPostmanCollectionModal()}
                    disableRipple={true}
                    sx={{ py: 0, px: 0, '&: hover': { background: 'transparent' } }}>
                    Import
                  </Button>
                </Tooltip>
              </Flex>

              <>
                <Flex columnGap={0} sx={{ mt: 1, px: 1.5 }}>
                  <TextField
                    disabled={!!massSelection.active}
                    size={'small'}
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    fullWidth
                    placeholder="Search"
                    inputProps={{
                      style: {
                        height: '14px',
                        mb: 4
                      }
                    }}
                  />
                  <Tooltip title={'Create new folder'}>
                    <IconButton
                      disabled={!!massSelection.active}
                      size={'small'}
                      sx={{ borderRadius: '8px' }}
                      onClick={() => requestCreateFolderModal()}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    sx={{
                      display:
                        !massSelection.selectedApiTests.length &&
                        !massSelection.selectedApiTestExamples.length
                          ? 'none'
                          : ''
                    }}
                    onClick={() => {
                      confirmTestsDeletion();
                    }}>
                    <DeleteSvg width={'18px'} height={'18px'} />
                  </IconButton>
                  <Tooltip title={!massSelection.active ? 'select multiple' : ''}>
                    <IconButton size={'small'} sx={{ borderRadius: '16px' }}>
                      {!massSelection.active ? (
                        <PlaylistAddCheckIcon
                          onClick={() => {
                            setMassSelection((prev) => {
                              return { ...prev, active: true };
                            });
                          }}
                          fontSize="medium"
                          sx={{
                            mr: 1
                          }}
                        />
                      ) : (
                        <Close
                          onClick={() => {
                            setMassSelection({
                              active: false,
                              selectedApiTests: [],
                              selectedApiTestExamples: [],
                              selectedFolders: [],
                              selectedSuites: []
                            });
                          }}
                          sx={{
                            color: 'rgba(100,100,100, 0.7)',
                            '&:hover': {
                              color: 'rgba(100,100,100)'
                            }
                          }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Flex>
                <TestFolders
                  isDeletingFolder={isDeletingFolder}
                  requestFolderDeleteConfirm={requestFolderDeleteConfirm}
                  requestSaveChangesDialog={requestSaveChangesDialog}
                  requestCreateFolderModal={requestCreateFolderModal}
                  isDuplicating={isDuplicating}
                  requestTestDeleteConfirm={requestTestDeleteConfirm}
                  requestConfirmDuplicate={requestConfirmDuplicate}
                  requestRenameTestModal={requestRenameTestModal}
                  requestRenameFolderModal={requestRenameFolderModal}
                  requestExampleDeleteConfirm={requestExampleDeleteConfirm}
                  isSavingTest={isSavingTest}
                  handleSaveTest={handleSaveTest}
                  debounceSearchValue={debounceSearchValue}
                  testsPage={testsPage}
                  setTestpage={setTestpage}
                  // folders={folders}
                  refetchFoldersAndTests={refetchFoldersAndTests}
                  isFetchingAllFoldersAndTests={isFetchingAllFoldersAndTests}
                  // tests={tests}
                  // refetchTests={refetchTests}
                  error={folderAndTestErrors}
                  resetInitialState={resetInitialState}
                  foldersAndTests={allFolders}
                />
              </>
            </Flex>
            <Flex
              sx={{
                // outline: '2px solid black',
                flexDirection: 'column',
                flex: '70%',
                alignItems: 'normal',
                pt: 1,
                pl: 2,
                overflowX: 'hidden'
              }}>
              {(isPageLoading ||
                isRunningTest ||
                isSavingTest ||
                isRunningTestLocally ||
                !!isRunningTestByDesktopAgent) && (
                <Box sx={{ width: '100%', mt: -1 }}>
                  <LinearProgress />
                </Box>
              )}
              <Flex
                flexDirection={'column'}
                sx={{
                  // outline: '3px solid red',
                  position: 'relative',
                  columnGap: 0.7,
                  alignItems: 'flex-start'
                }}>
                <Box>
                  <Tabs
                    value={selectedTab}
                    onChange={(event, value) => setSelectedTab(value)}
                    indicatorColor={'secondary'}
                    variant="scrollable"
                    scrollButtons
                    sx={{
                      borderBottom: `3px solid ${theme.palette.table.main}`,
                      minHeight: 0,
                      maxHeight: 'max-content'
                    }}
                    TabIndicatorProps={{
                      sx: { height: 3, m: 0 }
                    }}>
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>Params</Typography>
                          {!!hasValidQueryParams && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />

                    <Tab
                      // disabled
                      disableRipple={true}
                      label={'Authorization'}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>
                            Headers{' '}
                            <Typography display={'inline'} fontSize={11}>
                              {!!hasValidQueryHeaders ? `( ${numberOfHeaders()} )` : ''}
                            </Typography>
                          </Typography>
                          {!!hasValidQueryHeaders && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>Body</Typography>
                          {!!rawContent && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>Cookies</Typography>
                          {hasValidCookies && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={'Assertions'}
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                  </Tabs>
                </Box>
                <Box
                  width={'100%'}
                  gap={1}
                  position={'relative'}
                  flexDirection={'row'}
                  alignItems={'start'}
                  sx={{
                    display: 'flex'
                  }}>
                  <TabPanel value={selectedTab} index={0}>
                    <Typography mt={2} fontWeight={'medium'} fontSize={'0.8rem'}>
                      Query Params
                    </Typography>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '230px' }}>
                      <QueryParamsTable
                        queryParams={queryParams}
                        url={url}
                        setUrl={setUrl}
                        setQueryParams={setQueryParams}
                        // updateUrlWithParams={updateUrlWithParams}
                      />
                      {!!pathVariables.length && (
                        <>
                          <Typography mt={2} fontWeight={'medium'} fontSize={'0.8rem'}>
                            Path Variables
                          </Typography>
                          <PathVariablesTable />{' '}
                        </>
                      )}
                    </CustomScrollbar>
                  </TabPanel>
                  <Box
                    sx={{
                      display: selectedTab !== 1 ? 'none' : ''
                    }}>
                    <Authorization
                      httpMethod={httpMethod}
                      url={url}
                      authorizationHeader={authorizationHeader}
                      setAuthorizationHeader={setAuthorizationHeader}
                      setQueryParams={setQueryParams}
                      setQueryHeaders={setQueryHeaders}
                      globalVariableState={globalVariableState}
                    />
                  </Box>
                  <TabPanel value={selectedTab} index={2}>
                    <Typography mt={2} fontSize={'0.8rem'}>
                      Headers
                    </Typography>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '350px' }}>
                      <Headers queryHeaders={queryHeaders} setQueryHeaders={setQueryHeaders} />
                    </CustomScrollbar>
                  </TabPanel>

                  <TabPanel value={selectedTab} index={3}>
                    <QueryBody
                      queryHeaders={queryHeaders}
                      setQueryHeaders={setQueryHeaders}
                      rawContent={rawContent}
                      setRawContent={setRawContent}
                      requestBodyType={requestBodyType}
                      setRequestBodyType={setRequestBodyType}
                      formData={formData}
                      setFormData={setFormData}
                      formUrlEncoded={formUrlEncoded}
                      setFormUrlEncoded={setFormUrlEncoded}
                      language={language}
                      setLanguage={setLanguage}
                      graphqlVariables={graphqlVariables}
                      setGraphQLVariables={setGraphQLVariables}
                    />
                  </TabPanel>

                  <TabPanel value={selectedTab} index={4}>
                    <Typography mt={2} fontSize={'0.8rem'}>
                      Cookies
                    </Typography>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '250px' }}>
                      <CookiesTabPage cookies={requestCookies} setCookies={setRequestCookies} />
                    </CustomScrollbar>
                  </TabPanel>
                  <TabPanel value={selectedTab} index={5}>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                      <Box sx={{ width: '50%', pt: 3 }}>
                        {!!dropdowns.length &&
                          dropdowns.map((dropdown, index) => (
                            <Box key={index}>
                              <Flex
                                alignItems={'normal'}
                                justifyContent={'flex-start'}
                                flexDirection={'column'}
                                key={index}>
                                <TestAssertionss
                                  key={index}
                                  dropdown={dropdown}
                                  index={index}
                                  setDropdowns={setDropdowns}
                                  dropDownArray={dropdowns}
                                  handleOneOfInsertions={handleOneOfInsertions}
                                  handleRangeLimit={handleRangeLimit}
                                  handleOptionSelect={handleOptionSelect}
                                  handleOperatorSelect={handleOperatorSelect}
                                  onTextFieldChange={handleTextFieldChange}
                                  handleCreateVariableFromJson={handleCreateVariableFromJson}
                                  handleAssertHeaderValue={handleAssertHeaderValue}
                                  onDelete={handleDeleteButtonClick}
                                />
                              </Flex>
                              <Divider sx={{ mb: 1.5, width: '95.5%' }} />
                            </Box>
                          ))}

                        <Chip
                          label={`Add new`}
                          onClick={handleAddButtonClick}
                          size={'small'}
                          color={'primary'}
                          icon={<AddIcon />}
                          sx={{
                            borderRadius: '0.3rem',
                            py: 2,
                            mt: 1
                          }}
                        />
                      </Box>
                    </CustomScrollbar>
                  </TabPanel>

                  <Divider orientation="vertical" flexItem />
                  <ToolBar />
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 15,
                      background: theme.palette.background.white_black,
                      boxShadow: ` -3px -3px 12px ${theme.palette.background.shadow}`,
                      borderRadius: 2,
                      top: 30,
                      right: 42
                    }}>
                    <Documentation />
                    <TestDetails />
                  </Box>
                </Box>
              </Flex>
              <Box sx={{ borderTop: `3px solid ${theme.palette.custom.grey}` }} ref={responseRef}>
                {!showResponseProps && (
                  <Box height={'235px'}>
                    <Typography fontSize={'0.9rem'} mt={2}>
                      Response
                    </Typography>
                    <Flex justifyContent={'center'} flexDirection={'column'}>
                      <ResponseSvg width={90} height={90} />
                      {!url.length ? (
                        <Typography variant={'body2'} textAlign={'center'} mt={1}>
                          Enter a URL and click <br /> send to get a response
                        </Typography>
                      ) : (
                        <Typography variant={'body2'} textAlign={'center'} mt={1}>
                          Click Send to get a response
                        </Typography>
                      )}
                    </Flex>
                  </Box>
                )}
                {!!showResponseProps && !!showRunProperty && (
                  <Flex justifyContent={'space-between'}>
                    <Tabs
                      variant="scrollable"
                      scrollButtons="auto"
                      value={responseTab}
                      onChange={(event, value) => setResponseTab(value)}
                      indicatorColor={'secondary'}
                      sx={{
                        borderBottom: `2px solid ${theme.palette.table.main}`,
                        minHeight: 0,
                        maxHeight: 'max-content'
                      }}
                      TabIndicatorProps={{
                        sx: { height: 3 }
                      }}>
                      <Tab
                        disabled={!apiResponse && !responseFile.fileBase64}
                        label={'Body'}
                        disableRipple={true}
                        sx={{
                          ...tabStyle,
                          mr: 3
                        }}
                      />

                      <Tab
                        label={'Cookies'}
                        disableRipple={true}
                        sx={{
                          ...tabStyle,
                          mr: 3
                        }}
                      />
                      <Tab
                        disabled={responseHeaders?.length > 0 ? false : true}
                        label={
                          <Flex columnGap={'2px'}>
                            <Typography fontSize={'0.85rem'}>Headers</Typography>
                            <Typography
                              fontSize={'0.80rem'}
                              color={'primary'}
                              sx={{
                                borderRadius: '100%',
                                p: '3px'
                              }}>{`(${responseHeaders?.length})`}</Typography>
                          </Flex>
                        }
                        disableRipple={true}
                        sx={{
                          ...tabStyle,
                          mr: 3
                        }}
                      />
                      <Tab
                        label={
                          <Flex columnGap={'1px'}>
                            <Typography fontSize={'0.85rem'}>Assertion result</Typography>
                            {!!Object.values(assertionsResults).filter(Boolean).flat().length && (
                              <Badge
                                sx={{
                                  ml: 2.5
                                }}
                                color={
                                  !!Object.values(assertionsResults)
                                    .filter(Boolean)
                                    .flat()
                                    .filter((result) => result.status === 'failed').length
                                    ? 'error'
                                    : 'primary'
                                }
                                badgeContent={
                                  <Flex
                                    borderRadius={10}
                                    sx={{
                                      mt: 0.2,
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}>
                                    <Typography fontSize={10}>
                                      {
                                        Object.values(assertionsResults)
                                          .filter(Boolean)
                                          .flat()
                                          .filter((result) => result.status === 'passed').length
                                      }
                                      <Typography display={'inline'} fontSize={10}>
                                        /
                                        {
                                          Object.values(assertionsResults).filter(Boolean).flat()
                                            .length
                                        }
                                      </Typography>
                                    </Typography>
                                  </Flex>
                                }
                              />
                            )}
                          </Flex>
                        }
                        disableRipple={true}
                        sx={{
                          ...tabStyle,
                          mr: 3
                        }}
                      />
                      <Tab
                        label={
                          <Flex columnGap={'5px'}>
                            <Typography fontSize={'0.85rem'}>
                              Console
                              <Typography display={'inline'} ml={1.5}></Typography>
                            </Typography>
                            {!!isAssertionResultsNotNull && (
                              <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                            )}
                          </Flex>
                        }
                        disableRipple={true}
                        sx={{
                          ...tabStyle,
                          mr: 3
                        }}
                      />
                    </Tabs>
                    {(!!showResponseProps || !!responseFile.isFile) && !exampleId && (
                      <Flex columnGap={1}>
                        {/* <Typography fontSize={'0.8rem'}>
                          <span style={{ fontWeight: 'bold' }}>Status</span>: {status}
                          {getStatusText(status)}
                        </Typography> */}
                        <Typography fontSize={'0.8rem'} fontWeight={'medium'}>
                          Status:{' '}
                          <Typography
                            display={'inline'}
                            fontSize={'0.8rem'}
                            color={statusCodeColorMap[status?.toString().charAt(0)] || ''}>
                            {' '}
                            {status}
                          </Typography>
                          <Typography
                            ml={0.4}
                            display={'inline'}
                            color={statusCodeColorMap[status.toString().charAt(0)] || ''}>
                            {getStatusText(status)}
                          </Typography>
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography fontSize={'0.8rem'} fontWeight={'medium'}>
                          Time taken:
                          <Typography
                            ml={0.4}
                            fontSize={11}
                            display={'inline'}
                            style={{ fontWeight: 'light' }}>
                            {convertTimeToSeconds(timeTaken)}
                          </Typography>
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography fontSize={'0.8rem'} fontWeight={'medium'}>
                          Size:
                          <Typography
                            ml={0.4}
                            fontSize={11}
                            display={'inline'}
                            style={{ fontWeight: 'light' }}>
                            {size}
                          </Typography>
                        </Typography>
                      </Flex>
                    )}
                    {!!showResponseProps && !!exampleId && (
                      <Flex columnGap={1}>
                        <Typography fontSize={'0.8rem'}>
                          <span style={{ fontWeight: 'bold' }}>Status code</span>: {status}
                          {getStatusText(status)}
                        </Typography>
                      </Flex>
                    )}
                  </Flex>
                )}
                <TabPanel value={responseTab} index={0}>
                  {(!!showResponseProps || !!responseFile.isFile) && (
                    <Box mb={2}>
                      <ResponseEditor
                        showRunProperty
                        checkForFile={responseFile}
                        data={apiResponse}
                        editorState={responseEditorState}
                        setEditorState={setResponseEditorState}
                        handleEditorStateChange={handleResponseEditorStateChange}
                        copyResponseToClipBoard={copyResponseToClipBoard}
                        requestSaveTestExampleModal={requestSaveTestExampleModal}
                        showSaveResponseBTN={showSaveResponseBTN}
                      />
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={responseTab} index={1}>
                  {!!responseCookies.length ? (
                    <ResponseCookies responseCookies={responseCookies} />
                  ) : (
                    <Flex justifyContent={'center'} flexDirection={'column'} mt={2}>
                      <NoCookiesSvg />
                      <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
                        No cookies received from the server
                      </Typography>
                      <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
                        All your cookies and their associated domains will appear here.
                      </Typography>
                    </Flex>
                  )}
                </TabPanel>
                {responseHeaders.length > 0 && (
                  <TabPanel value={responseTab} index={2}>
                    <ResponseHeader responseHeaders={responseHeaders} />
                  </TabPanel>
                )}

                <TabPanel value={responseTab} index={3}>
                  {!isAssertionResultsNotNull && (
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '200px' }}>
                      <Flex justifyContent={'center'} flexDirection={'column'} mt={2}>
                        <NoTestAssertionsSvg />
                        <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
                          There are no tests for this request
                        </Typography>
                        <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
                          Make assertions to automate debugging
                        </Typography>
                      </Flex>
                    </CustomScrollbar>
                  )}
                  {!!isAssertionResultsNotNull && (
                    <>
                      <Flex columnGap={0} my={'5px'}>
                        <Chip
                          label={'All'}
                          variant={'outlined'}
                          onClick={() => setAssertionResultTab(0)}
                          sx={{
                            borderRadius: 0,
                            border: 'none',
                            bgcolor:
                              assertionResultTab === 0
                                ? 'rgba(220,220,220, 0.5)'
                                : theme.palette.background.lightGrey_dark,
                            color: assertionResultTab === 0 ? '#1958B8' : 'inherit',
                            py: 1,
                            px: 1.5,
                            '&: hover': {
                              color: '#1958B8',
                              backgroundColor: 'rgba(220,220,220, 0.6)'
                            }
                          }}
                        />
                        <Chip
                          label={'Passed'}
                          variant={'outlined'}
                          onClick={() => setAssertionResultTab(1)}
                          sx={{
                            border: 'none',
                            borderRadius: 0,
                            bgcolor:
                              assertionResultTab === 1
                                ? 'rgba(220,220,220, 0.5)'
                                : theme.palette.background.lightGrey_dark,
                            color: assertionResultTab === 1 ? '#1958B8' : 'inherit',
                            py: 1,
                            px: 1,
                            '&: hover': {
                              color: '#1958B8',
                              backgroundColor: 'rgba(220,220,220, 0.6)'
                            }
                          }}
                        />
                        <Chip
                          label={'Failed'}
                          variant={'outlined'}
                          onClick={() => setAssertionResultTab(2)}
                          sx={{
                            borderRadius: 0,
                            border: 'none',
                            bgcolor:
                              assertionResultTab === 2
                                ? 'rgba(220,220,220, 0.5)'
                                : theme.palette.background.lightGrey_dark,
                            color: assertionResultTab === 2 ? '#1958B8' : 'inherit',
                            py: 1,
                            px: 1,
                            '&: hover': {
                              color: '#1958B8',
                              backgroundColor: 'rgba(220,220,220, 0.6)'
                            }
                          }}
                        />
                      </Flex>
                      <TabPanel value={assertionResultTab} index={0}>
                        <AllAssertionResults results={assertionsResults} status_code={status} />
                      </TabPanel>
                      <TabPanel value={assertionResultTab} index={1}>
                        <PassedAssertionResults results={assertionsResults} status_code={status} />
                      </TabPanel>
                      <TabPanel value={assertionResultTab} index={2}>
                        <FailedAssertionResults results={assertionsResults} status_code={status} />
                      </TabPanel>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={responseTab} index={4}>
                  <Console />
                </TabPanel>
              </Box>
            </Flex>
          </Flex>

          {/* {isPageLoading && <PageLoader height={'100px'} />} */}

          <GetTestTitle
            open={openTitleModal}
            handleClose={() => setOpenTitleModal(false)}
            handleSave={async (title) => {
              return await handleSaveTest(title);
            }}
          />

          <SaveRequestModal
            open={saveRequestModal}
            onClose={onCloseSaveRequestModal}
            onComplete={completeSaveRequestModal}
            title={title}
            setTitle={setTitle}
          />

          <CreateFolder
            open={openCreateFolderModal}
            onClose={onCloseCreateFolderModal}
            onComplete={completeCreateFolderModal}
            refetchFolders={refetchFoldersAndTests}
          />

          <SaveTestExampleModal
            open={openSaveTestExampleModal}
            onClose={onCloseSaveTestExampleModal}
            onComplete={completeSaveTestExampleModal}
            refetch={() => {
              refetchFoldersAndTests();
              // refetchTests();
              // refetchFolders();
            }}
            data={testData()}
            response={runsResult || savedTest?.response || null}
            assertions={updateTestAssertion()}
          />

          <SavePostmanCollection
            open={openImportPostmanCollectionModal}
            onClose={onCloseImportPostmanCollectionModal}
            onComplete={completeImportPostmanCollectionModal}
            refetch={() => {
              refetchFoldersAndTests();
              // refetchTests();
              // refetchFolders();
            }}
          />
        </>
      )}

      {isFeatureFlagsLoading && <PageLoader />}
      {!isFeatureFlagsLoading && !showAPIMenu && (
        <ErrorState
          title={'Permission Denied'}
          description={
            "We're sorry, but you do not have the necessary permissions to access this page."
          }
        />
      )}
      <GlobalModal
        globalVariableState={globalVariableState}
        setGlobalVariableState={setGlobalVariableState}
        setVariableServerState={setVariableServerState}
        setDropdowns={setDropdowns}
        setAssertJSONResponseField={setAssertJSONResponseField}
        dropdowns={dropdowns}></GlobalModal>
      <DescriptionModal />
      {showVariableFloatingWindow && (
        <VariablesList
          variables={variablesServerState}
          ref={variableSuggestionList}
          setRawContent={setRawContent}
        />
      )}
      <SaveTextAsVariable setGlobalVariableState={setGlobalVariableState} />
      <DeleteSelectedApiTests
        runRequest={() => {
          return massDelete({
            // folders: [...massSelection.selectedFolders],
            test_cases: [...massSelection.selectedApiTests],
            examples: [...massSelection.selectedApiTestExamples]
          });
        }}
        runningRequest={isMassDeleting}
        // requestSuccessful={deleteSuccessful}
        title={`Are you sure you want to proceed`}
        description={'This process is irreversible'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <SaveChangesDialogNavToNew
        isSavingTest={isSavingTest}
        useCase={'save-changes'}
        title={'Saved Changes'}
        handleSaveTest={handleSaveTest}
        runRequest={handleNavToNewAfterSavingChanges}
        description={'You have unsaved changes, are you sure you want to leave?'}
        confirmLabel={['Back', 'Proceed, do not save', 'Save, then proceed']}
      />
      <SaveChangesDialogNavToTest
        isSavingTest={isSavingTest}
        useCase={'save-changes'}
        title={'Saved Changes'}
        handleSaveTest={handleSaveTest}
        runRequest={handleNavToTestAfterSavingChanges}
        description={'You have unsaved changes, are you sure you want to leave?'}
        confirmLabel={['Back', 'Proceed, do not save', 'Save, then proceed']}
      />
      <RenameModal
        useCase={'folder'}
        open={openRenameFolderModal}
        onClose={onCloseRenameFolderModal}
        onComplete={completeRenameFolderModal}
        refetch={refetchFoldersAndTests}
      />
      <RenameModal
        useCase={'test'}
        open={openRenameTestModal}
        onClose={onCloseRenameTestModal}
        onComplete={completeRenameTestModal}
        refetch={refetchFoldersAndTests}
      />
      <ConfirmationDuplicateDialog
        runningRequest={isDuplicating}
        runRequest={DuplicateTest}
        title={'Are you sure you want to duplicate this test request?'}
        description={
          'By duplicating this test request, a new request will be created. The new test request will be identical to the original, and you can edit it as needed.'
        }
        confirmLabel={'Duplicate'}
      />
      <DeleteConfirmationDialog
        runningRequest={isDeleting}
        runRequest={deleteTest}
        title={'Are you sure you want to delete this test request?'}
        description={'When you delete a test request, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <FolderDeleteConfirmationDialog
        runningRequest={isDeletingFolder}
        runRequest={deleteFolder}
        title={'Are you sure you want to delete this folder?'}
        description={'When you delete a folder, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <TestExampleDeleteConfirmationDialog
        runningRequest={isDeletingExample}
        runRequest={deleteTestExample}
        title={'Are you sure you want to delete this request example?'}
        description={'When you delete a request example, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <CreateSystemVariableForApi
        setRawContent={setRawContent}
        open={openDynamicValues}
        onClose={() => {
          setOpenDynamicValues(false);
        }}
      />
    </Box>
  );
};
export default NewApi;
const parseUrlOrAnyStringForglobalVariable = (url, variables) => {
  const regex = /(\{\{.*?\}\}|\/)/g;
  const splitUrl = url.split(regex).filter(Boolean);
  const newUrl = splitUrl.map((item) => {
    if (item.startsWith('{{') && !!variables.length) {
      const text = item.replace(/{{|}}/g, '');
      const entry = variables?.find((entry) => {
        return entry.name === text;
      });
      if (!!entry) {
        return entry.value;
      }
      return item;
    } else {
      return item;
    }
  });
  return newUrl.join('');
};
const parseStringForGlobalAndDynamicValues = (str, variables) => {
  let parsedValue = processVariableStringForApi(str); // first check for dynamic variables
  parsedValue = parseUrlOrAnyStringForglobalVariable(parsedValue, variables); // then global variables
  return parsedValue;
};
const functionToCreateResponseBody = async (response) => {
  const textBodyTypes = [
    'text',
    'html',
    'text/plain',
    'text/html',
    'application/javascript',
    'application/xml'
    // 'application/octet-stream'
  ];
  const blobBodyTypes = [
    'image/jpeg',
    'image/png',
    'jpeg',
    'png',
    'application/pdf'
    // 'application/octet-stream'
  ];
  let body;
  let isBlob = false;
  let contentType = 'text/plain';
  if (!!response.headers.get('Content-type' || 'content-type')) {
    contentType = response.headers.get('Content-type' || 'content-type');
  }
  try {
    if (contentType.includes('json')) {
      body = await response.json();
    } else if (textBodyTypes.includes(contentType.split(';')[0])) {
      body = await response.text();
    } else if (blobBodyTypes.includes(contentType)) {
      body = await response.blob();
      isBlob = true;
    } else {
      body = await response.text();
    }
  } catch (error) {
    throw error;
  }
  return { body, isBlob, contentType };
};
const functionToExtractResponseHeaders = (_headers) => {
  let headers = [];
  _headers.forEach((value, name) => {
    headers.push({ key: name, value: value });
  });
  return headers;
};

const replaceDynamicBooleanValueInJSONBody = (str) => {
  return str.replace(/"{{([^}]+)}}"/g, (match, p1) => {
    const booleanValue = generateRandomBoolean();
    if (p1.trim() === 'boolean()') {
      return booleanValue; // Replace with the actual value returned by boolean()
    }
    return match; // Return the original match if it doesn't match
  });
};
